import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ChatHistorySideMenuItem from "./ChatHistorySideMenuItem";
import { getChatsApi, deleteChatApi } from "../domain/myApisConsumption";
import { toast } from "react-toastify";

function SideMenus({
  menuType,
  menuSelected,
  sideMenuBenchMarkingSelected,
  newChatClicked,
  chatHistoryItemSelected,
  myChatItemClicked,
  newChatItemToSend,
}) {
  let sideMenuAccountClass = "";
  let sideMenuBillingClass = "";
  let sideMenuUsageClass = "";
  if (menuSelected === "account") sideMenuAccountClass = "sideMenuSelected";
  else if (menuSelected === "billing")
    sideMenuBillingClass = "sideMenuSelected";
  else if (menuSelected === "usage") sideMenuUsageClass = "sideMenuSelected";

  const userInfo = useSelector((state) => state.userInfo) || {};
  const [sideMenuFirstBenchMarking, setSideMenuFirstBenchMarking] =
    useState("sideMenuSelected");
  const [sideMenuSecondBenchMarking, setSideMenuSecondBenchMarking] =
    useState("");
  const [sideMenuThirdBenchMarking, setSideMenuThirdBenchMarking] =
    useState("");
  const [sideMenuFourthBenchMarking, setSideMenuFourthBenchMarking] =
    useState("");

  const [myArrayForChatHistory, setMyArrayForChatHistory] = useState([]);
  // const [myArrayForChatHistory, setMyArrayForChatHistory] =
  //   useState([
  //     { id: 0, selected: false, title: "first content" },
  //     {
  //       id: 2,
  //       selected: false,
  //       title:
  //         "This is just a test, as it is something new to calculate the derivate of something",
  //     },
  //   ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let myChatsResponse = await getChatsApi(userInfo.id);

        let newArray = myChatsResponse.data.chats
          .map((item, i) => {
            return { ...item, selected: false };
          })
          .filter((item) => item.deleted === false)
          .sort((a, b) => new Date(a.last_update) - new Date(b.last_update));
        setMyArrayForChatHistory(newArray);
      } catch (e) {
        console.log(e);
        toast.error("Couldn't fetch your chat history");
      }
    };
    if (menuType === "chat") fetchData();
    return;
  }, []);

  useEffect(() => {
    if (!newChatItemToSend) {
      return;
    }
    let newArray = [...myArrayForChatHistory];
    newArray.push({
      chat_id: newChatItemToSend.chat_id,
      title: newChatItemToSend.title,
      selected: true,
    });
    setMyArrayForChatHistory(newArray);
  }, [newChatItemToSend]);

  const newChat = () => {
    newChatClicked();
  };
  const itemSelected = (id) => {
    setMyArrayForChatHistory((prev) =>
      prev.map((item) => {
        if (item.chat_id === id) {
          return { ...item, selected: true };
        } else {
          return { ...item, selected: false };
        }
      })
    );
    chatHistoryItemSelected(id);
  };
  const deleteItem = async (id) => {
    console.log(id);
    try {
      let myDeleteResp = await deleteChatApi(id);
      console.log(myDeleteResp);
      setMyArrayForChatHistory((prev) =>
        prev.filter((item) => item.chat_id !== id)
      );
    } catch (error) {
      console.log(error);
      toast.error("Couldn't delete this chat");
    }
  };
  const chatItemClicked = () => {
    myChatItemClicked();
  };
  const changeTitle = (title, id) => {
    setMyArrayForChatHistory((prev) =>
      prev.map((item) => {
        if (item.chat_id === id) {
          return { ...item, title: title };
        } else return { ...item };
      })
    );
  };
  return (
    <>
      {menuType === "settings" && (
        <div>
          <div className="row" style={{ marginTop: 20 , marginBottom: 100}}>
            <div className="col-12 text-center">
              {" "}
              <img alt="" src="/images/sideMenuSettings.svg"></img>
            </div>
          </div>
          <div className="row" style={{ marginTop: 30 }}>
            <Link to={"/updateProfile"}>
              {" "}
              <div
                className={`sideMenuOption firstSideMenuOptionBorder ${sideMenuAccountClass}`}
              >
                <label className="sideMenuLabels ">Account</label>
              </div>
            </Link>
            <Link to={"/billing"}>
              {" "}
              <div
                className={`sideMenuOption nextSideMenuOptionBorder  ${sideMenuBillingClass}`}
              >
                <label className="sideMenuLabels ">Billing</label>
              </div>
            </Link>
            <Link to={"/usage"}>
              {" "}
              <div
                className={`sideMenuOption thirdSideMenuOptionBorder ${sideMenuUsageClass}`}
              >
                <label className="sideMenuLabels">Usage</label>
              </div>
            </Link>
          </div>
        </div>
      )}

      {menuType === "chat" && (
        <div>
          <div className="row" style={{ marginBottom: 100}}>
            <div
              className="col-12 text-center newChatButtonDiv"
              onClick={newChat}
            >
              <div style={{ marginTop: 20 }}>
                <img
                  style={{ marginTop: -10 }}
                  alt=""
                  src="/images/new chat button icon.svg"
                ></img>
                <span className="ms-2 benchMarkingOrChatLabel">
                  New chat‎ ‎ ‎ ‎ ‎{" "}
                </span>
              </div>
            </div>
          </div>

          {/* <div className="row" style={{ marginTop: 27 }}>
            <div className="col-12 text-center">
              <div style={{ marginTop: 20, marginBottom: 10 }}>
                <img
                  style={{ marginTop: -10 }}
                  alt=""
                  src="/images/chat-history icon.svg"
                ></img>
                <span className="ms-2 benchMarkingOrChatLabel">
                  Chat history
                </span>
              </div>
            </div>
          </div> */}

          <div className="row mt-4">
            <div className="col-12 text-left">
              {myArrayForChatHistory
                .slice()
                .reverse()
                .map((myElement, index) => (
                  <ChatHistorySideMenuItem
                    chatItemClicked={chatItemClicked}
                    historyItemSelected={itemSelected}
                    deleteItem={deleteItem}
                    changeTitle={changeTitle}
                    index={index}
                    id={myElement.chat_id}
                    key={myElement.chat_id}
                    selected={myElement.selected}
                    title={myElement.title}
                  />
                ))}
            </div>
          </div>
        </div>
      )}

      {menuType === "benchMarking" && (
        <div className="container">
          <div className="row" style={{ marginTop: 135 }}>
            <div className="col-12 text-center">
              {" "}
              <label className="benchMarkingOrChatLabel">Select Chat</label>
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSideMenuFirstBenchMarking("sideMenuSelected");
                setSideMenuSecondBenchMarking("");
                setSideMenuThirdBenchMarking("");
                setSideMenuFourthBenchMarking("");
                sideMenuBenchMarkingSelected("first");
              }}
              className={`sideMenuOption2 firstSideMenuOptionBorder ${sideMenuFirstBenchMarking}`}
            >
              <label className="sideMenuLabels ">Integral calculation</label>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSideMenuFirstBenchMarking("");
                setSideMenuSecondBenchMarking("sideMenuSelected");
                setSideMenuThirdBenchMarking("");
                setSideMenuFourthBenchMarking("");
                sideMenuBenchMarkingSelected("second");
              }}
              className={`sideMenuOption2 firstSideMenuOptionBorder ${sideMenuSecondBenchMarking}`}
            >
              <label className="sideMenuLabels ">Riemann sum for integral evaluation</label>
            </div>{" "}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSideMenuFirstBenchMarking("");
                setSideMenuSecondBenchMarking("");
                setSideMenuThirdBenchMarking("sideMenuSelected");
                setSideMenuFourthBenchMarking("");
                sideMenuBenchMarkingSelected("third");
              }}
              className={`sideMenuOption2 thirdSideMenuOptionBorder ${sideMenuThirdBenchMarking}`}
            >
              <label className="sideMenuLabels">Power series to elementary functions</label>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSideMenuFirstBenchMarking("");
                setSideMenuSecondBenchMarking("");
                setSideMenuThirdBenchMarking("");
                setSideMenuFourthBenchMarking("sideMenuSelected");
                sideMenuBenchMarkingSelected("fourth");
              }}
              className={`sideMenuOption2 thirdSideMenuOptionBorder ${sideMenuFourthBenchMarking}`}
            >
              <label className="sideMenuLabels">Proving the divergence of a sequence</label>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SideMenus;
