import React, { useState, useEffect, useRef } from "react";
import MathJax from "../lib";

const DynamicWordDisplay = ({
  expression,
  parentCallback,
  endDisplayInChild,
}) => {
  const [text, setText] = useState("");

  const [showCursor, setCursor] = useState(true);
  const endNow = useRef(false);

  useEffect(() => {
    let currentCharIndex = 0;

    const typeNextChar = () => {
      const currentMessage = expression.words;
      // const currentChar = currentMessage.charAt(currentCharIndex);
      let currentChunk;

      currentChunk = currentMessage.slice(
        currentCharIndex,
        currentCharIndex + 10
      );

    setText((prevText) => prevText + currentChunk);

      if (endDisplayInChild) {
        endNow.current = true;
        setCursor(false);
        parentCallback();
        return;
      } else if (
        currentCharIndex < currentMessage.length - 1 &&
        !endNow.current
      ) {
        currentCharIndex += 10;
        setTimeout(typeNextChar, 10);

        // currentCharIndex += 1;
        // setTimeout(typeNextChar, 20); // Adjust the interval to control typing speed
      } else {
        setCursor(false);
        parentCallback();
      }
    };

    typeNextChar(); // Start typing when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDisplayInChild]);

  // useEffect(() => {
  //   if (endDisplayInChild) {
  //     setCursor(false);
  //     parentCallback();
  //   }
  // }, [endDisplayInChild]);

  return (
    <>
      <span style={{ whiteSpace: "pre-wrap" }}>
        {text.split(/\*\*(.*?)\*\*/g).map((part, index) => {
          if (index % 2 === 0) {
            return <MathJax.Text inline text={part} />; // Normal text
          } else {
            return (
              <strong key={index}>
                <MathJax.Text inline text={part} />{" "}
              </strong>
            ); // Bold text
          }
        })}
        {showCursor && (
          <img
            style={{
              marginBottom: "1px",
              height: "10px",
              width: "10px",
            }}
            src="images/black dote.png"
            alt="cursor"
          />
        )}
      </span>
    </>
  );
};

export default DynamicWordDisplay;
