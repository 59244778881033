import axios from "axios";

// begin of auth users apis
let url;
let portPython;
if (window.location.hostname !== "localhost") {
  url = "https://app.epsilonia.com";
  portPython = "4043";
} else {
  url = "http://localhost";
  portPython = "4043";
}
// signup

export function signup(user) {
  return axios({
    method: "post",
    url: url + ":8080/users/signup",

    data: {
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
    },
  });
}

export function deleteUserByPhone(phone, email) {
  return axios({
    method: "delete",
    url: url + `:8080/users/deleteUserByPhoneAndMail/${phone}/${email}`,
  });
}
export function deleteUserById(user, idToken) {
  return axios({
    method: "delete",
    url: url + `:8080/users/deleteUserById`,
    headers: {
      authtoken: idToken,
    },
    data: {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
    },
  });
}

export function editProfileById(idToken, firstName, lastName, id) {
  return axios({
    method: "put",
    url: url + ":8080/users/updateProfile",
    headers: {
      authtoken: idToken,
    },
    data: {
      firstName: firstName,
      lastName: lastName,
      id: id,
    },
  });
}

export function getUserByMailApi(mail, idToken) {
  return axios({
    method: "get",
    url: url + ":8080/users/getUserByMail/" + mail,

    headers: {
      authtoken: idToken,
    },
  });
}
export function getUserByIdApi(id, idToken) {
  return axios({
    method: "get",
    url: url + ":8080/users/getUserById/" + id,

    headers: {
      authtoken: idToken,
    },
  });
}
export function getCustomerHistoryPaymentsApi(id, idToken) {
  return axios({
    method: "get",
    url: url + ":8080/stripe/getCustomerHistoryPayment/" + id,

    headers: {
      authtoken: idToken,
    },
  });
}

// console.log(idToken);
// return axios.get(url+":8080/users/getUserByMail?email=" + mail, {
//   headers: {
//     authtoken: idToken,
//   },
// });

// end of auth users apis

// stripe api
// export function stripeCheckOut(user_id, price) {
//   return axios({
//     method: "post",
//     url: url+":8080/stripe/create-checkout-session",

//     data: {
//       id: user_id,
//       price: price,
//     },
//   });
// }

export function getUserPaymentMethods(id, idToken) {
  return axios({
    method: "get",
    url: url + `:8080/stripe/getUserPaymentMethods/${id}`,
    headers: {
      authtoken: idToken,
    },
  });
}
export function addPaymentMethodApi(stripeToken, FBToken, idUser, isDefault) {
  return axios({
    method: "post",
    url: url + ":8080/stripe/addPaymentMethod",
    headers: {
      authtoken: FBToken,
    },
    data: {
      id: idUser,
      stripeToken: stripeToken,
      isDefault: isDefault,
    },
  });
}
export function deleteCardMethodApi(idCard, FBToken, idUser) {
  return axios({
    method: "delete",
    url: url + ":8080/stripe/deleteCardMethod",
    headers: {
      authtoken: FBToken,
    },
    data: {
      id: idUser,
      idCard: idCard,
    },
  });
}
export function makeDefaultCardApi(idCard, FBToken, idUser) {
  return axios({
    method: "put",
    url: url + ":8080/users/makeDefaultCard",
    headers: {
      authtoken: FBToken,
    },
    data: {
      id: idUser,
      idCard: idCard,
    },
  });
}

export function submitPaymentApi(amount, FBToken, idUser, cardId) {
  return axios({
    method: "post",
    url: url + ":8080/stripe/submitPayment",
    headers: {
      authtoken: FBToken,
    },
    data: {
      idUser: idUser,
      amount: amount,
      cardId: cardId,
    },
  });
}

// python api calls

export function newChatApi(user_id, question, option) {
  console.log(user_id+" "+question+" "+option)
  return axios({
    method: "post",
    url: url + "/api_python"+"/new_chat",
    data: {
      user_id: user_id,
      question: question,
      option: option,
    },
  });
}
export function addQuestionApi(user_id, chat_id, question, option) {
  return axios({
    method: "put",
    url: url + "/api_python" + "/add_question",

    data: {
      user_id: user_id,
      chat_id: chat_id,
      question: question,
      option: option,
    },
  });
}
export function editQuestionApi(user_id, chat_id, question, index) {
  return axios({
    method: "put",
    url: url +"/api_python" + `/edit_chat_question?msg_index=${index}`,

    data: {
      user_id: user_id,
      chat_id: chat_id,
      question: question,
      option: "",
    },
  });
}
export function addFeedBackApi(chat_id, index, thumb, comment) {
  return axios({
    method: "put",
    url:
      url +"/api_python"+`/add_feedback?msg_index=${index}&chat_id=${chat_id}`,

    data: {
      thumb: thumb,
      comment: comment,
    },
  });
}
export function renameChatApi(chat_id, title) {
  return axios({
    method: "put",
    url:
      url +"/api_python"+`/rename_chat?chat_id=${chat_id}&new_title=${title}`,
  });
}
export function deleteChatApi(chat_id) {
  return axios({
    method: "delete",
    url: url + "/api_python" + `/delete_chat?chat_id=${chat_id}`,
  });
}
export function getChatsApi(user_id) {
  return axios({
    method: "get",
    url: url + "/api_python" + `/chats?user_id=${user_id}`,
  });
}
export function getSpecificChatByIdApi(chat_id) {
  return axios({
    method: "get",
    url: url + "/api_python" + `/chat?chat_id=${chat_id}`,
  });
}
export function getUserCreditAPI(user_id) {
  return axios({
    method: "get",
    url: url + "/api_python" + `/get_user_credit?user_id=${user_id}`,
  });
}
export function getTokenUsageApi(user_id) {
  return axios({
    method: "get",
    url: url + "/api_python" + `/usage?user_id=${user_id}`,
  });
}
