import * as constants from "../actions/constants";

const initialStates = { userInfo: {} };

const UserReducer = (state = initialStates, action) => {
  const { type } = action;

  if (type === constants.INITIALIZE_USER) {
    return {
      ...state,
      userInfo: action.payload,
    };
  } else if (type === constants.LOG_OUT_USER) {
    return initialStates;
  }

  else if (type === constants.UPDATE_NAME_LAST_NAME) {
    let data = { ...state.userInfo };
    data.firstName = action.payload.firstName;
    data.lastName = action.payload.lastName;
    return {
      ...state,
      userInfo: data,
    };
  }
};

export default UserReducer;
