import { useNavigate } from "react-router-dom";
import { auth } from "../util/firestore";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import * as actions from "../actions/usersActions";

function Header({ logoPath, backgounrColor, signedIn }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = async () => {
    try {
      await auth.signOut();
      dispatch(actions.logOutUserAction({}));
      navigate("/");
      window.location.reload(false);
    } catch (e) {
      console.log(e);
      toast(
        "An unexpected error occured while trying to logout, please try again! If this persists, please reprt this error with the code e-800",
        { autoClose: false }
      );
    }

    // navigate("/");
  };
  const navigateLandingPage = () => {
    navigate("/");
  };
  const navigateChatBot = () => {
    navigate("/");
  };
  const navigateProfileInfo = () => {
    navigate("/updateProfile");
  };

  const epsibotIconUrl = "/images/epsibot-icon.svg";
  const settingsIconUrl = "/images/settings-icon.svg";
  const logoutIconUrl = "/images/logout-icon.svg";

  const iconStyle = {
    width: "20px", // Set the desired width
    height: "20px", // Set the desired height
    marginRight: "8px", // Space between the icon and the text
    verticalAlign: "up",
  };

  return (
    <>
      {!signedIn && (
        <div
          style={{
            height: "63px",
            width: "100%",
            backgroundColor: backgounrColor,
          }}
        >
          <img
            alt=""
            style={{ cursor: "pointer" }}
            src={logoPath}
            onClick={navigateLandingPage}
          />
        </div>
      )}

      {signedIn && (
        <div
          style={{
            height: "63px",
            width: "100%",
            backgroundColor: backgounrColor,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            alt=""
            style={{ marginRight: 48, cursor: "pointer" }}
            src={logoPath}
            onClick={navigateChatBot}
          />

          <button className="headerButtons" onClick={navigateChatBot}>
            {" "}
            &nbsp;
            <img src={epsibotIconUrl} alt="Icon" style={iconStyle} />
            EpsiBot
          </button>
          <button className="headerButtons" onClick={navigateProfileInfo}>
            {" "}
            &nbsp;
            <img src={settingsIconUrl} alt="Icon" style={iconStyle} />
            Settings
          </button>
          <button className="headerButtons" onClick={logout}>
            <img src={logoutIconUrl} alt="Icon" style={iconStyle} />
            Logout
          </button>
        </div>
      )}
    </>
  );
}

export default Header;
