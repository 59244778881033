import React from "react";
import MathJax from "../lib";
function BenchMarkingItem({ bot, title, userMessage, botMessage }) {
  return (
    <>
      <div className="col">
        <div
          style={{
            height: "100%",
            overflow: "scroll",
            overflowY: "hidden",
            overflowX: "hidden",
          }}
          className="showAxisX"
        >
          <div>
            <div className="row">
              <div className="col-12 text-center">
                <div className="row" style={{ marginTop: 30 }}>
                  {" "}
                  <div className="col-1"></div>
                  {(bot === "ChatGPT" || bot === "Gemini") && (
                    <div className="benchMarkingTitlesDivRed col-10">
                      <label className="benchMarkingTitlesLabelRed">
                        {title}
                      </label>
                    </div>
                  )}
                  {bot === "EpsiBot" && (
                    <div className="benchMarkingTitlesDivGreen col-10">
                      <label className="benchMarkingTitlesLabelGreen">
                        {title}
                      </label>
                    </div>
                  )}
                  <div className="col-1"></div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-1"></div>
              <div className="col-10 text-left d-flex">
                <span className="circle-chat">{"U"}</span>
                <label className="senderLabel">User</label>
              </div>

              <div className="col-1"></div>
            </div>
            <div className="row mt-1">
              <div className="col-1"></div>

              <div className="col-10 text-left d-flex">
                <span
                  className="senderLabelMessage"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <MathJax.Text inline text={userMessage} />{" "}
                </span>
              </div>

              <div className="col-1"></div>
            </div>
          </div>

          <div>
            <div className="row mt-3">
              <div className="col-1"></div>
              <div className="col-10 text-left d-flex">
                {bot === "EpsiBot" && (
                  <img
                    style={{ height: 30, width: 30 }}
                    alt=""
                    src="/images/epsibotIcon.svg"
                  ></img>
                )}
                {bot === "ChatGPT" && (
                  <img
                    style={{ height: 30, width: 30 }}
                    alt=""
                    src="/images/chatGptIcon.svg"
                  ></img>
                )}
                {bot === "Gemini" && (
                  <img
                    style={{ height: 30, width: 30 }}
                    alt=""
                    src="/images/geminiIcon.svg"
                  ></img>
                )}

                <label className="senderLabel">{bot}</label>
              </div>

              <div className="col-1"></div>
            </div>
            <div className="row mt-1">
              <div className="col-1"></div>
              <div className="col-10 text-left d-flex">
                {botMessage !== "gpt2" && (
                  <span
                    className="senderLabelMessage"
                    style={{
                      whiteSpace: "pre-line",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      maxWidth: "100%",
                    }}
                  >
                    <MathJax.Text text={botMessage} />{" "}
                  </span>
                )}
                {botMessage === "gpt2" && (
                  <span
                    className="senderLabelMessage"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    <MathJax.Text
                      inline
                      text={`To calculate the integral \\(\\int_{-1}^2 (2x + x^2) \\, dx\\) as the limit of the right Riemann sum \\(R_n\\), we will follow these steps: `}
                    />
                    <br></br>
                    <strong>
                      {" "}
                      <MathJax.Text
                        inline
                        text={`1. Divide the interval \\([-1, 2]\\) into \\(n\\) equal parts.`}
                      />
                    </strong>
                    <br></br>
                    <MathJax.Text
                      inline
                      text={`The length of the interval is \\(2 - (-1) = 3\\), so each subinterval will have a length of \\(\\Delta x = \\frac{3}{n}\\).`}
                    />
                    <br></br>
                    <strong>
                      {" "}
                      {
                        "2. Determine the x-values for the right endpoints of each subinterval."
                      }{" "}
                    </strong>
                    <MathJax.Text
                      inline
                      text={`The right endpoint of the \\(k\\)-th subinterval is given by \\(x_k = -1 + k\\Delta x\\), for \\(k = 1, 2, ..., n\\). `}
                    />
                    <br></br>
                    <strong>
                      {" "}
                      <MathJax.Text
                        inline
                        text={`3. Calculate the Riemann sum \\(R_n\\)`}
                      />
                    </strong>
                    <br></br>
                    <MathJax.Text
                      inline
                      text={`The Riemann sum is the sum of the function values at these right endpoints, multiplied by the width of each subinterval:
   \\[R_n = \\sum_{k=1}^{n} (2x_k + x_k^2)\\Delta x\\]`}
                    />
                    <strong>
                      {" "}
                      <MathJax.Text
                        inline
                        text={`4. Take the limit as \\(n\\) approaches infinity.`}
                      />
                    </strong>
                    <br></br>
                    <MathJax.Text
                      inline
                      text={`The exact value of the integral is the limit of the Riemann sums as \\(n\\) approaches infinity:
                      \\[\\lim_{n \\to \\infty} R_n\\]`}
                    />
                    <MathJax.Text
                      inline
                      text={`It seems there was an error in directly computing the limit of the Riemann sum using the symbolic approach. However, we can still analytically solve the problem by expressing the Riemann sum formula and then calculating the integral directly.
                      For the function \\(f(x) = 2x + x^2\\), the integral over the interval \\([-1, 2]\\) can be calculated directly using the fundamental theorem of calculus. The Riemann sum approach is a conceptual step towards understanding the integral, but given the error in computation, we will directly integrate the function and understand that the result of this integration is what the limit of the Riemann sums approaches as \\(n\\) approaches infinity.
                      Let's calculate the integral \\(\\int_{-1}^2 (2x + x^2) \\, dx\\) directly.
                      The integral \\(\\int_{-1}^2 (2x + x^2) \\, dx\\) evaluates to \\(6\\). This result is what the limit of the right Riemann sum \\(R_n\\) approaches as \\(n\\) approaches infinity.
                      `}
                    />
                    <br></br>
                  </span>
                )}
              </div>

              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BenchMarkingItem;
