import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import swal from "sweetalert2";
let firebaseConfig;

if (window.location.hostname !== "localhost") {
  firebaseConfig = {
    apiKey: "AIzaSyCm-LAAkF9O9qlZvBtZ8oPp5_MFFxDDUAw",
    authDomain: "epsilonia-prod.firebaseapp.com",
    projectId: "epsilonia-prod",
    storageBucket: "epsilonia-prod.appspot.com",
    messagingSenderId: "31809236257",
    appId: "1:31809236257:web:20ab820672194b6c996260",
    measurementId: "G-WBHFMZHCQM",
  };
} else
  firebaseConfig = {
    apiKey: "AIzaSyAV2mgvoInVjQ7NyDEbc0GysHFOTljy_M8",
    authDomain: "epsilonia-platform.firebaseapp.com",
    projectId: "epsilonia-platform",
    storageBucket: "epsilonia-platform.appspot.com",
    messagingSenderId: "223724947721",
    appId: "1:223724947721:web:a8dedec2322deee9426bd8",
    measurementId: "G-6ZK9MQ1RDV",
  };

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const getIdToken = async () => {
  try {
    let idToken = await auth.currentUser.getIdToken();

    return idToken;
  } catch (e) {
    console.log("fail token or get current user");

    swal.fire({
      imageUrl: "/images/swal-error.png",
      imageWidth: 80,
      imageHeight: 80,
      html: "<h5>An unexpected error has occured.Try refreshing the page please</h5>",
      showConfirmButton: true,
    });
    return false;
  }
};
