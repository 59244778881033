import { useState } from "react";

function HoverableImageLink({
  linkUrl,
  defaultSrc,
  hoverSrc,
  height,
  width,
  margin,
}) {
  const handleMouseOver = (e) => {
    e.target.src = hoverSrc;
  };

  const handleMouseOut = (e) => {
    e.target.src = defaultSrc;
  };

  return (
    <a href={linkUrl} target="_blank" style={{ margin }}>
      <img
        src={defaultSrc}
        alt=""
        style={{ height, width }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      />
    </a>
  );
}

function Footer() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div className="epsiFooter">
        <div className="container-fluid">
          <div className="row" style={{ paddingTop: 30 }}>
            <div className="col-3">
              <img alt="" src="images/backgroundLogoWhite.svg"></img>
            </div>
            <div className="col-5">
              <div className="row">
                <div className="col-12">
                  <label className="footerLabels">
                    © {new Date().getFullYear()} Epsilonia Corp. All rights
                    reserved.
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="footerLabels">
                    {" "}
                    Epsilonia Corp. is an educational technology startup created
                    in 2023 and located in Ottawa, Ontario, Canada.{" "}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="footerLabels">
                  </label>
                </div>
              </div>
            </div>
            <div className="col-4 text-center">
              <div className="row">
                <div className="col-12">
                  <HoverableImageLink
                    linkUrl="https://www.facebook.com/EpsiloniaMath"
                    defaultSrc="images/facebook.svg"
                    hoverSrc="images/facebook-hover.svg"
                    height={40}
                    width={40}
                    margin={5}
                  />

                  <HoverableImageLink
                    linkUrl="https://twitter.com/epsiloniamath"
                    defaultSrc="images/twitter.svg"
                    hoverSrc="images/twitter-hover.svg"
                    height={40}
                    width={40}
                    margin={5}
                  />

                  <HoverableImageLink
                    linkUrl="https://www.instagram.com/epsiloniamath/"
                    defaultSrc="images/instagram.svg"
                    hoverSrc="images/instagram-hover.svg"
                    height={40}
                    width={40}
                    margin={5}
                  />

                  <HoverableImageLink
                    linkUrl="https://www.youtube.com/@EpsiloniaMath"
                    defaultSrc="images/youtube.svg"
                    hoverSrc="images/youtube-hover.svg"
                    height={40}
                    width={40}
                    margin={5}
                  />

                  <HoverableImageLink
                    linkUrl="https://www.linkedin.com/company/epsiloniamath/"
                    defaultSrc="images/linkedin.svg"
                    hoverSrc="images/linkedin-hover.svg"
                    height={40}
                    width={40}
                    margin={5}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    className="emailLinkButton"
                  >
                    {" "}
                    {!isHovered && (
                      <a
                        className="emailLinkAnchor"
                        href="mailto:hello@epsilonia.com"
                      >
                        {" "}
                        hello@epsilonia.com
                      </a>
                    )}
                    {isHovered && (
                      <a
                        className="emailLinkAnchorHover"
                        href="mailto:hello@epsilonia.com"
                      >
                        {" "}
                        hello@epsilonia.com
                      </a>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
