import { useState } from "react";

const SavedCardMethod = ({
  id,
  type,
  brand,
  last4,
  exp_month,
  exp_year,
  deleteCardMethod,
  isDefault,
  isSelected,
  switchSelected,
  makeDefaultCardMethod,
  loadingBool,
}) => {
  const deleteMethod = () => {
    if (loadingBool) {
      return;
    }
    deleteCardMethod(id);
  };
  const changeSelectedCard = () => {
    if (type === "inAddPaymentMethod") return;
    switchSelected(id);
  };

  let classContainer;
  if (isSelected && type !== "inAddPaymentMethod") {
    classContainer = "cardContainerSelected";
  }

  const classPointer = type === "inAddPaymentMethod" ? "" : "classPointer";

  const makeDefault = () => {
    if (loadingBool) {
      return;
    }
    makeDefaultCardMethod(id);
  };

  return (
    <>
      <div
        onClick={changeSelectedCard}
        className={`card-container col-12 ${classContainer}`}
      >
        <div className="row">
          <div className="col-4">
            {brand === "visa" && (
              <img height={50} width={50} alt="" src="images/visa.png"></img>
            )}
            {brand === "mastercard" && (
              <img
                height={50}
                width={50}
                alt=""
                src="images/mastercard.svg"
              ></img>
            )}
            {brand === "discover" && (
              <img
                height={50}
                width={50}
                alt=""
                src="images/discover.png"
              ></img>
            )}
            {brand === "amex" && (
              <img
                height={50}
                width={50}
                alt=""
                src="images/americanexpress.png"
              ></img>
            )}
            {brand === "unionpay" && (
              <img
                height={50}
                width={50}
                alt=""
                src="images/chinunionpay.png"
              ></img>
            )}
            {brand === "jcb" && (
              <img height={50} width={50} alt="" src="images/jcb.png"></img>
            )}
            {brand === "diners" && (
              <img
                height={50}
                width={50}
                alt=""
                src="images/dinnersclub.png"
              ></img>
            )}
            {brand === "bcard" && (
              <img
                height={50}
                width={50}
                alt=""
                src="images/dinnersclub.png"
              ></img>
            )}

            {brand !== "jcb" &&
              brand !== "diners" &&
              brand !== "bcard" &&
              brand !== "unionpay" &&
              brand !== "visa" &&
              brand !== "unionpay" &&
              brand !== "mastercard" &&
              brand !== "discover" &&
              brand !== "amex" && (
                <label className="cardBrandLabel">{brand}</label>
              )}
          </div>
          <div className="col-8">
            <div className="row">
              <label className={`cardNumberLabel ${classPointer}`}>
                {"...." + last4}
              </label>
            </div>
            <div className="row">
              <label className={`cardExpiresLabel ${classPointer}`}>
                {" "}
                Expires {exp_month}/{exp_year}
              </label>
            </div>
          </div>
        </div>
        {type === "inAddPaymentMethod" && (
          <div className="row">
            {" "}
            <div className="col-4"></div>
            <div className="col-8">
              <div className="row d-flex">
                <div className="col-4">
                  {" "}
                  <label onClick={deleteMethod} className="deleteCardLabel">
                    Delete
                  </label>
                </div>
                <div className="col-6">
                  {" "}
                  {isDefault && (
                    <label className="deleteCardLabel">Default card</label>
                  )}
                  {!isDefault && (
                    <label onClick={makeDefault} className="deleteCardLabel">
                      Make default
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SavedCardMethod;
