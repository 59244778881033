function SettingsHeader({ title }) {
  return (
    <>
      <div className="row" style={{ marginLeft: "40px" }}>
        <label className="titleSettingsLabel mt-3">{title}</label>
      </div>

      <div className="row" style={{ marginLeft: "52px" }}>
        <div className="col-5 mediumline"></div>
        <div className="col-1"></div>
      </div>
    </>
  );
}

export default SettingsHeader;
