import * as constants from "./constants";

export const initialiseUser = (user) => {
  return {
    type: constants.INITIALIZE_USER,
    payload: user,
  };
};


export const logOutUserAction = () => {
    return {
      type: constants.LOG_OUT_USER,
    };
  };

  export const updateNameLastNameAction = (data) => {
    return {
      type: constants.UPDATE_NAME_LAST_NAME,
      payload: data,
    };
  };