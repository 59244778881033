import { useEffect, useState, useRef } from "react";
import Header from "./Header";
import SideMenus from "./SideMenus";
import SettingsHeader from "./SettingsHeader";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { getIdToken } from "../util/firestore";
import SavedCardMethod from "./SavedCardMethod";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Helmet from "react-helmet";
import {
  getUserPaymentMethods,
  addPaymentMethodApi,
  deleteCardMethodApi,
  submitPaymentApi,
  getUserByIdApi,
  getCustomerHistoryPaymentsApi,
  makeDefaultCardApi,
  getUserCreditAPI,
} from "../domain/myApisConsumption";
import { useSelector } from "react-redux";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import {
  CardElement,
  AddressElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

function Billing() {
  const MySwal = withReactContent(Swal);
  const stripe = useStripe();
  const elements = useElements();
  const userInfo = useSelector((state) => state.userInfo) || {};
  const [visible, setVisible] = useState(false);
  const [addCreditBool, setAddCreditBool] = useState(false);
  const [loadingBool, setLoadingBool] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [addToCreditBalanceValue, setAddToCreditBalanceValue] = useState(5);
  const [userCredits, setUserCredits] = useState("...");
  const [showHistory, setShowHistory] = useState("loading");
  const [historyTableData, setHistoryTableData] = useState([]);
  const [defaultIsChecked, setDefaultIsChecked] = useState(false);
  const [continueAddCreditBool, setContinueAddCreditBool] = useState(false);
  const [showPricingToolTip, setShowPricingToolTip] = useState(false);
  const [loadingSendPaymentMethod, setLoadingSendPaymentMethod] =
    useState(false);
  const [
    loadingConfirmAddToCreditBalance,
    setLoadingConfirmAddToCreditBalance,
  ] = useState(false);
  let toolTipStyle = {
    position: "absolute",
    zIndex: 999,
  };

  var cardIdSelectedForPayment = useRef(undefined);
  var defaultCardId = useRef(undefined);
  var myCreditToAdd = useRef(undefined);

  useEffect(() => {
    toast.dismiss();
    // Call the async function
    fetchData("withPaymentHistory");
    getUserCredits();
  }, []);

  const getUserCredits = async () => {
    try {
      setLoadingBool(true);
      let idToken = null;
      idToken = await getIdToken();
      // Your asynchronous logic here, e.g., fetching customer ID from the server
      const response = await getUserByIdApi(userInfo.id, idToken);

      if (response.data.message === "success") {
        cardIdSelectedForPayment.current = response.data.user.defaultCard;
        defaultCardId.current = response.data.user.defaultCard;

        try {
          const reponseCredit = await getUserCreditAPI(userInfo.id);
          setUserCredits(reponseCredit.data.credit);
        } catch (e) {
          console.log(e);
          setUserCredits("error");
        }
      } else {
        setUserCredits("error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setUserCredits("error");
    }
  };
  const fetchData = async (type) => {
    try {
      setLoadingBool(true);

      let idToken = null;
      idToken = await getIdToken();
      // Your asynchronous logic here, e.g., fetching customer ID from the server
      const response = await getUserPaymentMethods(userInfo.id, idToken);

      if (response.data.data) {
        if (response.data.data.length > 0) {
          const updatedArray = response.data.data.map((item) => {
            // Check the condition to determine whether to modify the attributes
            if (item.id === cardIdSelectedForPayment.current) {
              // Modify the attributes of the object

              return {
                ...item,
                isSelected: true,
              };
            } else {
              return {
                ...item,
                isSelected: false,
              };
            }
          });

          setPaymentMethods(updatedArray);
        } else setPaymentMethods([]);
      } else setPaymentMethods([]);

      setLoadingBool(false);
      toast.dismiss();

      if (type === "withPaymentHistory") {
        try {
          let historyRes = await getCustomerHistoryPaymentsApi(
            userInfo.id,
            idToken
          );

          if (historyRes.data.message === "success_no_data") {
            setShowHistory("noData");
            toast.dismiss();
          }
          if (historyRes.data.message === "success") {
            setHistoryTableData(historyRes.data.data);

            setShowHistory("foundData");
            toast.dismiss();
          }
          if (historyRes.data.message === "error") {
            setLoadingBool(true);
            setShowHistory("error");
            toast.dismiss();
          }
        } catch (e) {
          console.log(e);
          setLoadingBool(true);
          setShowHistory("error");
          toast.dismiss();
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      setShowHistory("error");
      toast.dismiss();
      toast.error(
        "We couldn't fetch your payment methods, try refreshing the page!"
      );
      setLoadingBool(true);
    }
  };

  const payStripe = async () => {
    setAddCreditBool(true);
    // try {
    //   let stripeApi = await stripeCheckOut(userInfo.id, 10);
    //   if (stripeApi.data.url) {
    //     window.location.href = stripeApi.data.url;
    //   }
    // } catch (error) {
    //   console.log(error.message);
    // }
  };
  const addPaymentMethod = () => {
    setVisible(true);
  };

  const sendPaymentMethod = async () => {
    toast.dismiss();

    const cardNumberElement = elements?.getElement(CardElement);
    const adressElement = elements?.getElement(AddressElement);
    const cardElementContainer = document.querySelector("#card-element");
    let cardElementEmpty = cardElementContainer.classList.contains(
      "StripeElement--empty"
    );
    let cardElementInvalid = cardElementContainer.classList.contains(
      "StripeElement--invalid"
    );

    if (cardElementEmpty || cardElementInvalid) {
      toast.error("You must fill in your card info with valid data");
      return;
    }

    let adressRes = await adressElement.getValue();

    if (/\d/.test(adressRes.value.name)) {
      toast.error("You can't put numbers in your name");
      return;
    }
    if (/\d/.test(adressRes.value.address.city)) {
      toast.error("You can't put numbers in your city name");
      return;
    }

    if (adressRes.complete) {
      setLoadingBool(true);
      setLoadingSendPaymentMethod(true);
      // toast(
      //   <div>
      //     <div>
      //       <Spinner size="sm" /> {"Waiting reponse from the server..."}
      //     </div>
      //   </div>,
      //   {
      //     autoClose: false,
      //   }
      // );

      let userDataToSend = {
        name: adressRes.value.name,
        address_city: adressRes.value.address.city,
        address_line1: adressRes.value.address.line1,
        address_zip: adressRes.value.address.postal_code,
        address_country: adressRes.value.address.country,
      };

      if (adressRes.value.address.line2) {
        userDataToSend.address_line2 = adressRes.value.address.line2;
      }
      let stripeToken = await stripe.createToken(
        cardNumberElement,
        userDataToSend
      );
      if (stripeToken.token) {
        try {
          let idTokenFB = null;
          idTokenFB = await getIdToken();
          let addingRes = await addPaymentMethodApi(
            stripeToken.token,
            idTokenFB,
            userInfo.id,
            defaultIsChecked
          );
          if (
            addingRes.data.message === "No user found" ||
            addingRes.data.message === "error"
          ) {
            toast.dismiss();
            toast.error(
              "An error has occured, please report this error with the error code b-100!"
            );
            setLoadingBool(false);
            setLoadingSendPaymentMethod(true);
          } else if (
            addingRes.data.message === "couldn't create payment method"
          ) {
            toast.dismiss();
            toast.error(
              "Could not create payment method, please report this error with the error code b-200"
            );
            setLoadingBool(false);
            setLoadingSendPaymentMethod(true);
          } else if (addingRes.data.message === "Payment method created") {
            if (defaultIsChecked) {
              cardIdSelectedForPayment.current = addingRes.data.card;
              console.log(addingRes.data);
            }
            toast.dismiss();
            setVisible(false);
            setLoadingSendPaymentMethod(false);
            await MySwal.fire({
              icon: "success",
              text: "Payment method added succesfully!",
              buttonsStyling: false,
              customClass: {
                confirmButton: "swalConfirmButton",
              },
            }).then((result) => {});
            fetchData(undefined);
          }
        } catch (e) {
          console.log(e);
          toast.dismiss();
          toast.error("An error has occured, please try again!");
          setLoadingBool(false);
          setLoadingSendPaymentMethod(true);
        }
      } else {
        toast.dismiss();
        toast.error(
          "The payment method you entered might be deprecated, please try again!"
        );
        setLoadingBool(false);
        setLoadingSendPaymentMethod(true);
      }
    }
  };

  const deleteCardMethod = async (id) => {
    toast.dismiss();
    let yesDelete = false;
    await Swal.fire({
      customClass: "swal-delete-paymentmethod",
      showConfirmButton: false,
      html: `      <div class="container mt-4">
        <div class="row mt-5">
        <div class="col-12 text-center">
        <h3 >Are you sure you want to delete this payment method? </h3>
      </div>
        </div>
       <br>
        <div class="row">
        <div class="col-1"></div>
        <div class="col-4"><button class="addPaymentMethod">Yes, I'm sure</button></div>
        <div class="col-2"></div>
        <div class="col-4"><button class="cancelPaymentMethod">Cancel</button></div>
        <div class="col-1"></div>
        </div>

      </div>`,
      didOpen: async () => {
        const yes = document.querySelector(".addPaymentMethod");
        const no = document.querySelector(".cancelPaymentMethod");
        no.addEventListener("click", () => {
          Swal.close();
        });
        yes.addEventListener("click", async () => {
          yesDelete = true;
          Swal.close();
        });
      },
    }).then((result) => {
      Swal.close();
    });

    if (yesDelete) {
      setLoadingBool(true);
      // toast(
      //   <div>
      //     <div>
      //       <Spinner size="sm" /> {"Waiting reponse from the server..."}
      //     </div>
      //   </div>,
      //   {
      //     autoClose: false,
      //   }
      // );
      let idToken = null;
      try {
        idToken = await getIdToken();

        let DelCardReponse = await deleteCardMethodApi(
          id,
          idToken,
          userInfo.id
        );

        if (!DelCardReponse.data) {
          toast.dismiss();

          setLoadingBool(false);
          toast.error(
            "An unexpected error has occured, try loging out and then loging in"
          );
        } else if (DelCardReponse.data.message === "user inexistant") {
          toast.dismiss();

          setLoadingBool(false);
          toast.error(
            "An unexpected error has occured, try loging out and then loging in"
          );
        } else if (DelCardReponse.data.message === "card deleted succesfully") {
          if (cardIdSelectedForPayment.current === id) {
            cardIdSelectedForPayment.current = undefined;
          }

          toast.dismiss();
          // Swal.close();

          setLoadingBool(false);
          await Swal.fire({
            icon: "success",
            buttonsStyling: false,
            customClass: {
              confirmButton: "swalConfirmButton",
            },
            text: "Card deleted succesfully!",
          }).then((result) => {});
          fetchData(undefined);
        } else {
          toast.dismiss();

          setLoadingBool(false);
          toast.error("couldn't delete the card, try again laster");
        }
      } catch (e) {
        toast.dismiss();
        console.log(e);
        setLoadingBool(false);
        toast.error(
          "An unexpected error has occured, trying loging out and then loging in"
        );
      }
    }

    // await Swal.fire({
    //   showConfirmButton: true,
    //   showCancelButton: true,
    //   title: "Are you sure you want to delete this card?",

    //   confirmButtonText: "Yes, I'm sure",
    // }).then(async (result) => {
    //   if (result.value) {
    //     setLoadingBool(true);
    //     toast(
    //       <div>
    //         <div>
    //           <Spinner size="sm" /> {"Waiting reponse from the server..."}
    //         </div>
    //       </div>,
    //       {
    //         autoClose: false,
    //       }
    //     );
    //     let idToken = null;
    //     try {
    //       idToken = await getIdToken();

    //       let DelCardReponse = await deleteCardMethodApi(
    //         id,
    //         idToken,
    //         userInfo.id
    //       );

    //       if (!DelCardReponse.data) {
    //         toast.dismiss();

    //         setLoadingBool(false);
    //         toast.error(
    //           "An unexpected error has occured, try loging out and then loging in"
    //         );
    //       } else if (DelCardReponse.data.message === "user inexistant") {
    //         toast.dismiss();

    //         setLoadingBool(false);
    //         toast.error(
    //           "An unexpected error has occured, try loging out and then loging in"
    //         );
    //       } else if (
    //         DelCardReponse.data.message === "card deleted succesfully"
    //       ) {
    //         if (cardIdSelectedForPayment.current === id) {
    //           cardIdSelectedForPayment.current = undefined;
    //         }
    //         toast.dismiss();

    //         setLoadingBool(false);
    //         await MySwal.fire({
    //           icon: "success",
    //           text: "Card deleted succesfully!",
    //         }).then((result) => {});
    //         fetchData(undefined);
    //       } else {
    //         toast.dismiss();

    //         setLoadingBool(false);
    //         toast.error("couldn't delete the card, try again laster");
    //       }
    //     } catch (e) {
    //       toast.dismiss();
    //       console.log(e);
    //       setLoadingBool(false);
    //       toast.error(
    //         "An unexpected error has occured, trying loging out and then loging in"
    //       );
    //     }
    //   }
    // });
  };

  const confirmAddToCreditBalance = async () => {
    try {
      setLoadingBool(true);
      setLoadingConfirmAddToCreditBalance(true);

      // toast(
      //   <div>
      //     <div>
      //       <Spinner size="sm" /> {"Waiting reponse from the server..."}
      //     </div>
      //   </div>,
      //   {
      //     autoClose: false,
      //   }
      // );
      let idToken = null;
      idToken = await getIdToken();

      let responsePayment = await submitPaymentApi(
        parseFloat(myCreditToAdd.current) +
          parseFloat(((myCreditToAdd.current / 100) * 13).toFixed(2)),
        idToken,
        userInfo.id,
        cardIdSelectedForPayment.current
      );

      if (
        responsePayment.data.message === "payment unsuccesfull" ||
        responsePayment.data.message === "user inexistant"
      ) {
        toast.dismiss();
        setLoadingBool(false);
        setLoadingConfirmAddToCreditBalance(false);
        setAddCreditBool(false);

        await MySwal.fire({
          icon: "error",
          buttonsStyling: false,
          customClass: {
            confirmButton: "swalConfirmButton",
          },
          text: "Couldn't process your payment",
        }).then((result) => {
          fetchData("withPaymentHistory");
        });
      }
      if (
        responsePayment.data.message === "payment payed but no credits added"
      ) {
        toast.dismiss();
        setLoadingBool(false);
        setLoadingConfirmAddToCreditBalance(false);
        setAddCreditBool(false);
        await MySwal.fire({
          icon: "error",
          buttonsStyling: false,
          customClass: {
            confirmButton: "swalConfirmButton",
          },
          text: "Your payment was succesfull, but we couldn't add you credits, please contact us to solve this problem",
        }).then((result) => {
          fetchData("withPaymentHistory");
        });
      }
      if (responsePayment.data.message === "transaction succesfull") {
        toast.dismiss();
        setLoadingBool(false);
        setLoadingConfirmAddToCreditBalance(false);
        setUserCredits(responsePayment.data.credit);
        setAddCreditBool(false);
        setContinueAddCreditBool(false);
        await MySwal.fire({
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: "swalConfirmButton",
          },
          text: "Your transaction was successful!",
        }).then((result) => {
          fetchData("withPaymentHistory");
        });
      }
      if (responsePayment.data.message === "requires_confirmation") {
        toast.dismiss();
        setLoadingBool(false);
        setLoadingConfirmAddToCreditBalance(false);
        setAddCreditBool(false);
        await MySwal.fire({
          icon: "info",
          buttonsStyling: false,
          customClass: {
            confirmButton: "swalConfirmButton",
          },
          text: "Your transaction requires confirmation!",
        }).then((result) => {
          setContinueAddCreditBool(false);
          fetchData("withPaymentHistory");
        });
      }
    } catch (e) {
      toast.dismiss();
      setLoadingBool(false);
      setLoadingConfirmAddToCreditBalance(false);
      toast.error("An unexpected error has occured, please try again later");
      console.log(e);
    }
  };

  const continueAddToCreditBalance = async () => {
    let creditToAdd = addToCreditBalanceValue;
    toast.dismiss();
    if (!cardIdSelectedForPayment.current) {
      toast.error("You need to select a card to proceed");
      return;
    }

    if (addToCreditBalanceValue < 5 || addToCreditBalanceValue > 95) {
      toast.error("Select an amount between $5 and $95");
      return;
    }
    myCreditToAdd.current = creditToAdd;
    setAddCreditBool(false);
    setContinueAddCreditBool(true);
  };

  const switchSelected = (id) => {
    const updatedArray = paymentMethods.map((item) => {
      // Check the condition to determine whether to modify the attributes
      if (item.id === id) {
        // Modify the attributes of the object
        cardIdSelectedForPayment.current = id;
        return {
          ...item,
          isSelected: true,
        };
      } else {
        return {
          ...item,
          isSelected: false,
        };
      }
    });

    setPaymentMethods(updatedArray);
  };

  const makeDefaultCardMethod = async (id) => {
    toast.dismiss();
    setLoadingBool(true);

    let idToken = null;
    try {
      idToken = await getIdToken();

      let makeDefaultRes = await makeDefaultCardApi(id, idToken, userInfo.id);

      if (!makeDefaultRes.data) {
        toast.dismiss();

        setLoadingBool(false);
        toast.error(
          "An unexpected error has occured, try loging out and then loging in"
        );
      } else if (makeDefaultRes.data.message === "error") {
        toast.dismiss();

        setLoadingBool(false);
        toast.error(
          "An unexpected error has occured, try loging out and then loging in"
        );
      } else if (makeDefaultRes.data.message === "Default succesfull") {
        cardIdSelectedForPayment.current = id;
        defaultCardId.current = id;
        const updatedArray = paymentMethods.map((item) => {
          // Check the condition to determine whether to modify the attributes
          if (item.id === id) {
            // Modify the attributes of the object

            return {
              ...item,
              isSelected: true,
            };
          } else {
            return {
              ...item,
              isSelected: false,
            };
          }
        });
        setPaymentMethods(updatedArray);

        toast.dismiss();

        setLoadingBool(false);
        await MySwal.fire({
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: "swalConfirmButton",
          },
          text: "Operation succesfull!",
        }).then((result) => {});
      } else {
        toast.dismiss();
        setLoadingBool(false);
        toast.error("Couldn't make selected card as default");
      }
    } catch (e) {
      toast.dismiss();
      console.log(e);
      setLoadingBool(false);
      toast.error(
        "An unexpected error has occured, trying loging out and then loging in"
      );
    }
  };

  const addPaymentMethodFromAddCredit = () => {
    if (loadingBool) return;
    setAddCreditBool(false);
    setVisible(true);
  };

  const handleCreditToAddChange = (event) => {
    setAddToCreditBalanceValue(event.target.value);
  };

  const handleDefaultCardChange = (event) => {
    setDefaultIsChecked(event.target.checked);
  };

  const handleMouseEnterToolTip = async () => {
    await MySwal.fire({
      html: `<h5 class="signUpAndPassResetTitle">Pricing</h5>
      
      <div class="container">
        <div class="row">
          <div class="col-12 border border-left-0 border-right-0 border-black text-center"></div>
        </div>
      </div>
     
    
      <div class="container mt-4">
        <div class="row mt-5">
        <div class="col-1"></div>
        <div class="col-10 text-center">
        <p class="activationLinkText"> 0.03 USD / 1000 tokens </p>
        
      </div>
     `,
    }).then((result) => {});
  };

  // const handleMouseLeaveToolTip = () => {
  //   setPricingToolTip(true);
  // };

  const myTaxRounded = ((myCreditToAdd.current / 100) * 13).toFixed(2);

  return (
    <>
      {" "}
      <Helmet>
        <title>Epsibot billing</title>
      </Helmet>
      <div style={{ minWidth: 950 }}>
        <Header
          logoPath={"/images/backgroundLogoWhite.svg"}
          backgounrColor={"#0d41a6"}
          signedIn={true}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="sideMenucolumnInBilling">
              <SideMenus menuType={"settings"} menuSelected={"billing"} />
            </div>
            <div
              className="col contentColumn"
              // ref={bodyRef}
              style={{
                height: "100%",
                overflow: "scroll",
                overflowX: "hidden",
                overflowY: "hidden",
              }}
            >
              <SettingsHeader title={"Billing"} />
              <CModal
                alignment={"center"}
                visible={visible}
                onClose={() => setVisible(false)}
              >
                <CModalHeader onClose={() => setVisible(false)}>
                  <CModalTitle id="LiveDemoExampleLabel">
                    Add payment method
                  </CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <label>
                    Add your credit card details below. This card will be saved
                    to your account and can be removed at any time
                  </label>
                  <CardElement
                    id="card-element"
                    options={{
                      classes: { focus: "ACCFocusInput" },
                      autoFill: false,
                    }}
                    className="cardElement form-control mt-3"
                  />
                  <br />
                  <AddressElement options={{ mode: "billing" }} />
                  <br></br>
                  <div className="row">
                    <div className="col-8">
                      <div ClassName="form-check">
                        <input
                          ClassName="form-check-input"
                          type="checkbox"
                          onChange={handleDefaultCardChange}
                          id="flexCheckDefault"
                          checked={defaultIsChecked}
                        />
                        <label
                          className="form-check-label ms-1"
                          for="flexCheckDefault"
                        >
                          Set as default payment method
                        </label>
                      </div>
                    </div>
                    <div className="col-4"></div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-3"></div>
                    <div className="col-3">
                      {" "}
                      <button
                        disabled={loadingBool}
                        onClick={() => {
                          setVisible(false);
                        }}
                        className="cancelPaymentMethod"
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-5">
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={sendPaymentMethod}
                        className="addPaymentMethod"
                        disabled={loadingBool}
                      >
                        {!loadingSendPaymentMethod && (
                          <label style={{ cursor: "pointer" }}>
                            Add payment method
                          </label>
                        )}
                        {loadingSendPaymentMethod && <Spinner size="sm" />}
                      </button>
                    </div>
                  </div>
                  <br></br>
                </CModalBody>
              </CModal>
              <CModal
                alignment={"center"}
                visible={addCreditBool}
                onClose={() => setAddCreditBool(false)}
              >
                <CModalHeader
                  style={{ marginRight: 20, marginLeft: 20 }}
                  onClose={() => setAddCreditBool(false)}
                >
                  <CModalTitle id="LiveDemoExampleLabel">
                    Add to credit balance
                  </CModalTitle>
                </CModalHeader>
                <CModalBody style={{ marginRight: 20, marginLeft: 20 }}>
                  <div className="row">
                    <div className="col-6 addToCreditBalanceAndPaymentMethodLabels">
                      Add to credit balance
                    </div>
                    <div className="col-6"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 d-flex">
                      <div className="currencyDiv">
                        <label className="currencySignLabel">USD</label>
                      </div>
                      <input
                        style={{ paddingLeft: 10 }}
                        value={addToCreditBalanceValue}
                        min={5}
                        max={95}
                        className="selectAmountInput"
                        type="number"
                        onChange={handleCreditToAddChange}
                        placeholder="Select your ammount in USD"
                      />
                    </div>
                  </div>
                  <div className="row mt-1 d-flex">
                    <div className="col-10 addToCreditBalanceLabel">
                      <label className="amountToEnterLabelIndicator">
                        Enter an amount beween $5 and $95
                      </label>
                    </div>
                    <div className="col-1">
                      <label
                        onClick={() => {
                          setShowPricingToolTip(true);
                        }}
                        onMouseEnter={() => {
                          setShowPricingToolTip(true);
                        }}
                        onMouseLeave={() => {
                          setShowPricingToolTip(false);
                        }}
                        className="pricingLabel"
                      >
                        Pricing
                      </label>
                    </div>
                    <div className="col-1">
                      {showPricingToolTip && (
                        <div
                          // ref={toolTipDiv}
                          className="chatBotToolTip"
                          style={toolTipStyle}
                        >
                          <div class="container-fluid">
                            <div class="row">
                              <div class="col-12 mt-2 text-left">
                                <label
                                  style={{ marginTop: 5, cursor: "pointer" }}
                                >
                                  <p class="activationLinkText">
                                    {" "}
                                    Text input : 0.03 USD / 1000 tokens
                                  </p>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="container-fluid">
                            <div class="row">
                              <div class="col-12 mt-2 text-left">
                                <label
                                  style={{ marginTop: 5, cursor: "pointer" }}
                                >
                                  <p class="activationLinkText">
                                    Text output : 0.05 USD / 1000 tokens
                                  </p>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="container-fluid">
                            <div class="row">
                              <div class="col-12 mt-2 text-left">
                                <label
                                  style={{ marginTop: 5, cursor: "pointer" }}
                                >
                                  <p class="activationLinkText">
                                    Image input : 0.04 USD / 10000 px²
                                  </p>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-6 addToCreditBalanceAndPaymentMethodLabels">
                      Select payment method
                    </div>
                    <div className="col-6"></div>
                  </div>

                  {paymentMethods.length === 1 && (
                    <div
                      className="row mt-3"
                      style={{
                        height: 100,
                        overflow: "hidden",
                        overflowX: "hidden",
                      }}
                    >
                      <div className="col-12">
                        {paymentMethods.map((item) => (
                          <SavedCardMethod
                            key={item.id}
                            id={item.id}
                            type={"inAddCreditBalance"}
                            brand={item.card.brand}
                            last4={item.card.last4}
                            exp_month={item.card.exp_month}
                            exp_year={item.card.exp_year}
                            deleteCardMethod={deleteCardMethod}
                            isSelected={item.isSelected}
                            switchSelected={switchSelected}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {paymentMethods.length > 1 && (
                    <div
                      className="row mt-3"
                      style={{
                        height: 170,
                        overflow: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      <div className="col-12">
                        {paymentMethods.map((item) => (
                          <SavedCardMethod
                            key={item.id}
                            id={item.id}
                            type={"inAddCreditBalance"}
                            brand={item.card.brand}
                            last4={item.card.last4}
                            exp_month={item.card.exp_month}
                            exp_year={item.card.exp_year}
                            deleteCardMethod={deleteCardMethod}
                            isSelected={item.isSelected}
                            switchSelected={switchSelected}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="row mt-2">
                    <div className="col-7"></div>

                    <div className="col-5">
                      <label
                        onClick={addPaymentMethodFromAddCredit}
                        className="addPaymentMethodLabel"
                      >
                        + Add Payment method{" "}
                      </label>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-5"></div>

                    <div className="col-7 d-flex">
                      <button
                        disabled={loadingBool}
                        onClick={() => {
                          setAddCreditBool(false);
                        }}
                        className="cancelAddCreditBalanceLabel"
                      >
                        Cancel
                      </button>
                      <button
                        style={{ marginLeft: 20 }}
                        onClick={continueAddToCreditBalance}
                        className="continueAddCreditBalanceLabel"
                        disabled={loadingBool}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </CModalBody>
              </CModal>
              <CModal
                alignment={"center"}
                visible={continueAddCreditBool}
                onClose={() => setContinueAddCreditBool(false)}
              >
                <CModalHeader onClose={() => setContinueAddCreditBool(false)}>
                  <CModalTitle id="LiveDemoExampleLabel">
                    Confirm payment
                  </CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <div className="row">
                    <table id="data" class="table">
                      <thead>
                        <tr>
                          <th className="col-4">Description</th>
                          <th className="col-4"></th>
                          <th className="col-2"></th>
                          <th className="col-2">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="col-4">Epsibot usage credit</td>
                          <td className="col-4"></td>
                          <td className="col-2"></td>
                          <td className="col-2">
                            {" "}
                            {myCreditToAdd.current} USD
                          </td>
                        </tr>
                        <tr>
                          <td className="col-4"></td>
                          <td className="col-4"></td>
                          <td className="col-2">
                            Tax <br></br>
                            <label className="totalAmountLabel">Total</label>
                          </td>
                          <td className="col-2">
                            {myTaxRounded} USD <br></br>
                            <label className="totalAmountLabel">
                              {parseFloat(myCreditToAdd.current) +
                                parseFloat(myTaxRounded)}{" "}
                              USD
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-6 addToCreditBalanceAndPaymentMethodLabels">
                      Payment Method
                    </div>
                    <div className="col-6"></div>
                  </div>

                  {paymentMethods.length !== 0 && (
                    <div className="row mt-3">
                      <div className="col-12">
                        {paymentMethods
                          .filter(
                            (item) =>
                              item.id === cardIdSelectedForPayment.current
                          ) // Only include items where isSelected is true
                          .map((item) => (
                            <SavedCardMethod
                              key={item.id}
                              id={item.id}
                              type={"inAddCreditBalance"}
                              brand={item.card.brand}
                              last4={item.card.last4}
                              exp_month={item.card.exp_month}
                              exp_year={item.card.exp_year}
                              deleteCardMethod={deleteCardMethod}
                              isSelected={false}
                              switchSelected={switchSelected}
                            />
                          ))}
                      </div>
                    </div>
                  )}

                  <div className="row mt-2">
                    <div className="col-12">
                      By continuing you agree to our{" "}
                      <a href="creditsTerms" target="_blank">
                        service credit terms
                      </a>
                      . Paid credit are non-refundable
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-5"></div>

                    <div className="col-7 d-flex">
                      <button
                        disabled={loadingBool}
                        onClick={() => {
                          setContinueAddCreditBool(false);
                          setAddCreditBool(true);
                        }}
                        className="cancelAddCreditBalanceLabel"
                      >
                        Cancel
                      </button>
                      <button
                        style={{ marginLeft: 20 }}
                        onClick={confirmAddToCreditBalance}
                        className="continueAddCreditBalanceLabel"
                        disabled={loadingBool}
                      >
                        {!loadingConfirmAddToCreditBalance && (
                          <label style={{ cursor: "pointer" }}>Confirm</label>
                        )}
                        {loadingConfirmAddToCreditBalance && (
                          <Spinner size="sm" />
                        )}
                      </button>
                    </div>
                  </div>
                </CModalBody>
              </CModal>

              <div className="row mt-3">
                <div className="col-12">
                  <label
                    style={{ marginLeft: 52 }}
                    className="settingSubtitlesLabels"
                  >
                    Credit Balance
                  </label>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <label
                    style={{ marginLeft: 52, fontSize: 42.74 }}
                    className="settingSubtitlesLabels"
                  >
                    {userCredits !== "..." && userCredits !== "error" && (
                      <span> {userCredits} USD </span>
                    )}
                    {userCredits === "error" && <span>{"error"} </span>}
                    {userCredits === "..." && (
                      <span>
                        <Spinner size="sm" />
                      </span>
                    )}
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12">
                  <button
                    onClick={payStripe}
                    style={{ marginLeft: 52 }}
                    className="billingButton"
                    disabled={loadingBool}
                  >
                    Add to credit balance
                  </button>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "52px" }}>
                <div className="col-5 mediumline mt-3"></div>
                <div className="col-1"></div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <label
                    style={{ marginLeft: 52 }}
                    className="settingSubtitlesLabels"
                  >
                    Payment Method
                  </label>
                </div>
              </div>
              {/*  */}
              <div className="col-5">
                <div className="row" style={{ marginLeft: "40px" }}>
                  <div className="row mt-3">
                    <div className="col-12">
                      {paymentMethods.map((item) => (
                        <SavedCardMethod
                          key={item.id}
                          id={item.id}
                          type={"inAddPaymentMethod"}
                          brand={item.card.brand}
                          last4={item.card.last4}
                          exp_month={item.card.exp_month}
                          exp_year={item.card.exp_year}
                          isDefault={defaultCardId.current === item.id}
                          deleteCardMethod={deleteCardMethod}
                          makeDefaultCardMethod={makeDefaultCardMethod}
                          loadingBool={loadingBool}
                          isSelected={
                            cardIdSelectedForPayment.current === item.id
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="row" style={{ marginLeft: "40px" }}>
                <div className="row mt-3">
                  <div className="col-12">
                    <button
                      disabled={loadingBool}
                      onClick={addPaymentMethod}
                      className="billingButton"
                    >
                      Add payment method
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-1" style={{ marginLeft: "52px" }}>
                <div className="col-5 mediumline mt-3"></div>
                <div className="col-1"></div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <label
                    style={{ marginLeft: 52 }}
                    className="settingSubtitlesLabels"
                  >
                    Payment History
                  </label>
                </div>
              </div>
              {showHistory === "error" && (
                <div className="row mt-3">
                  <div className="col-12">
                    <label
                      style={{ marginLeft: 52 }}
                      className="paymentTimeLapseLabel"
                    >
                      Error:could not fetch your payment history
                    </label>
                  </div>
                </div>
              )}
              {showHistory === "loading" && (
                <div className="row mt-3">
                  <div className="col-1"></div>
                  <div className="col-6">
                    <label
                      style={{ marginLeft: 52 }}
                      className="paymentTimeLapseLabel"
                    >
                      <Spinner />
                    </label>
                  </div>
                  <div className="col-5"></div>
                </div>
              )}
              {showHistory === "noData" && (
                <div className="row mt-3">
                  <div className="col-1"></div>
                  <div className="col-6">
                    <img
                      height={140}
                      width={140}
                      alt=""
                      src="/images/no_data.jpg"
                    ></img>
                  </div>
                  <div className="col-5"></div>
                </div>
              )}
              {/* {showHistory === "foundData" && (
              <div className="row mt-3">
                <div className="col-9">
                  <label
                    style={{ marginLeft: 52 }}
                    className="paymentTimeLapseLabel"
                  >
                    Your last 20 payments
                  </label>
                </div>
              </div>
            )} */}
              {showHistory === "foundData" && (
                <div className="row mt-3" style={{ marginLeft: 40 }}>
                  <div
                    className="col-9"
                    style={{
                      height: historyTableData.length <= 20 ? "auto" : 870,
                      overflowY:
                        historyTableData.length > 20 ? "scroll" : "hidden",
                    }}
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th className="col-2">Reference</th>
                          <th className="col-2">Amount</th>
                          <th className="col-4">Date</th>
                          <th className="col-2">Status</th>
                          <th className="col-2">Receipt</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyTableData.map((item) => (
                          <tr>
                            <td className="col-2">{item.id}</td>
                            <td className="col-2">{item.amount / 100} USD </td>
                            <td className="col-4">
                              {new Date(item.created * 1000).toLocaleString()}
                            </td>
                            {item.status !== "requires_payment_method" && (
                              <td className="col-2">
                                {item.status.charAt(0).toUpperCase() +
                                  item.status.slice(1)}
                              </td>
                            )}
                            {item.status === "requires_payment_method" && (
                              <td className="col-2">Failed</td>
                            )}
                            <td className="col-2">
                              {item.latest_charge.receipt_url && (
                                <a
                                  href={item.latest_charge.receipt_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Billing;
