import React, { useEffect, useState, useRef } from "react";
import Icon from "@mdi/react";
import { mdiDotsHorizontal } from "@mdi/js";
import { renameChatApi } from "../domain/myApisConsumption";
import { toast } from "react-toastify";

function ChatHistorySideMenuItem({
  selected,
  title,
  id,
  index,
  historyItemSelected,
  deleteItem,
  changeTitle,
  chatItemClicked,
}) {
  let borderClass;
  const [myItemSelectionClass, setMyItemSelectionClass] = useState("");
  const [showHistoryItemOptions, setShowHistoryItemOptions] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [titleChange, setTitleChange] = useState(title);
  const [canChangeInput, setCanChangeInput] = useState(false);
  const [toolTipStyle, setToolTipStyle] = useState(null);
  var inputRef = useRef();
  const divRef = useRef(null);
  const toolTipDiv = useRef(null);

  borderClass =
    index === 0 ? "firstSideMenuOptionBorder" : "nextSideMenuOptionBorder";
  const selectItemAction = () => {
    historyItemSelected(id);
  };
  const deleteItemAction = () => {
    deleteItem(id);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      setCanChangeInput(false);
      try {
        await renameChatApi(id, titleChange);
        changeTitle(titleChange, id);
      } catch (error) {
        console.log("Couldn't rename your chat discussion ");
        toast.error("Couldn't rename your chat discussion ");
      }
    }
  };
  const handleBlur = async (event) => {
    setCanChangeInput(false);
    try {
      await renameChatApi(id, titleChange);
      changeTitle(titleChange, id);
    } catch (error) {
      console.log("Couldn't rename your chat discussion ");
      toast.error("Couldn't rename your chat discussion ");
    }
  };

  useEffect(() => {
    if (selected) setMyItemSelectionClass("sideMenuSelected");
    else setMyItemSelectionClass("");
  }, [selected]);

  useEffect(() => {
    if (canChangeInput) {
      inputRef.current.focus();
    }
  }, [canChangeInput]);

  const sideMenuOptionsClicked = (e) => {
    const height = divRef.current.clientHeight;
    console.log("Height of the div:", height);
    setToolTipStyle({
      marginTop: height - 10 + "px",
      // Add more styles as needed
    });

    setShowHistoryItemOptions(true);
    // chatItemClicked();
  };

  const handleClickOutside = (event) => {
    if (toolTipDiv.current && !toolTipDiv.current.contains(event.target)) {
      // Clicked outside the rectangle, so hide the tooltip
      setShowHistoryItemOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renameDiscussion = (event) => {
    setShowHistoryItemOptions(false);
    setCanChangeInput(true);
  };
  const deleteDiscussion = () => {
    setShowHistoryItemOptions(false);
    deleteItemAction();
  };

  return (
    <>
      {showHistoryItemOptions && (
        <div style={toolTipStyle} className="rectangle" ref={toolTipDiv}>
          <div class="container-fluid">
            <div class="row" onClick={renameDiscussion}>
              <div
                style={{ display: "flex", alignItems: "center" }}
                class="col-12 mt-2 rename-delete-popup renameActionClass"
              >
                <img alt="" src="/images/rename discussion.svg"></img>
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row" onClick={deleteDiscussion}>
              <div
                style={{ display: "flex", alignItems: "center" }}
                class="col-12 mt-2 rename-delete-popup deleteActionClass"
              >
                <img alt="" src="/images/delete discussion.svg"></img>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        ref={divRef}
        style={{ height: "auto", display: "flex" }}
        className={`sideMenuChatHistoryItem ${borderClass} ${myItemSelectionClass}`}
        onClick={selectItemAction}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <div
          className="col-1"
          onClick={(e) => {
            e.stopPropagation();
            sideMenuOptionsClicked(e);
          }}
        >
          {(isHovered || myItemSelectionClass === "sideMenuSelected") && (
            <Icon
              style={{ marginLeft: 10 }}
              path={mdiDotsHorizontal}
              size={0.8}
              color={"white"}
            />
          )}
        </div>
        <div className="col-11">
          {" "}
          {!canChangeInput && <label className="sideMenuLabels">{title}</label>}
          {canChangeInput && (
            <input
              ref={inputRef}
              className="sideMenuLabels myInvisibleInput"
              value={titleChange}
              onKeyDown={handleKeyPress}
              onBlur={handleBlur}
              onChange={(event) => setTitleChange(event.target.value)}
            ></input>
          )}
        </div>
      </div>
    </>
  );
}

export default ChatHistorySideMenuItem;
