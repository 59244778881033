import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import DynamicWordDisplay from "./DynamicWordDisplay";
import Icon from "@mdi/react";
import { mdiClipboard } from "@mdi/js";
import { mdiClipboardOutline } from "@mdi/js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import MathJax from "../lib";
import { addFeedBackApi } from "../domain/myApisConsumption";

function MessageSubmitted({
  sendedBy,
  message,
  type,
  id,
  index,
  sendEditMessage,
  setGeneratingResponseBool,
  isGeneratingMessage,
  scrollToBottom,
  messageEnded,
  chat_id,
  checkMyPreviousAnswer,
  endDisplay,
  length,
}) {
  const messagesDivRef = useRef(null);
  const userInfo = useSelector((state) => state.userInfo) || {};
  const MySwal = withReactContent(Swal);
  const regex = /\\\(.*?\\\)|\\\[.*?\\\]|\S+|\s+/gs;

  // Define a regular expression to match equations and words

  // Extract matches from the input string
  // const words = message.match(regex);
  const words = message;

  // const wordArray = words.split(/(\S+|\s)/g);
  const wordArray = words.split(" ");

  const [wordsArray, setWordsArray] = useState(() => {
    if (words[0][0] === "\\") {
      return [{ word: words[0], type: "math" }];
    } else return [{ word: words[0], type: "regular" }];
  });

  const [counter, setCounter] = useState(0);
  const [thumbUpHover, setThumbUpHover] = useState(false);
  const [clipBoardHover, setClipBoardHover] = useState(false);
  const [checkAnswerHover, setCheckAnswerHover] = useState(false);
  const [clipBoardCopied, setClipBoardCopied] = useState(false);
  const [thumbDownHover, setThumbDownHover] = useState(false);
  const [isMessageEditable, setIsMessageEditable] = useState(false);
  const [showFeedBackIcons, setShowFeedBackIcons] = useState(false);
  const [editButtonClassName, setEditButtonClassName] = useState("");
  const [editMessageTextAreaValue, setEditMessageTextAreaValue] =
    useState(message);

  const [tooltipText, setTooltipText] = useState("");
  const [endDisplayInChild, setEndDisplayInChild] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  // useImperativeHandle(ref, () => ({
  //   stopInChild: () =>{ alert("stopping")}
  // }));

  // useImperativeHandle(
  //   ref,
  //   () => {
  //     return {
  //       stopInChild() {
  //         alert("stopping");
  //       },
  //     };
  //   },
  //   []
  // );

  useEffect(() => {
    if (endDisplay) {
      setEndDisplayInChild(true);
    }
  }, [endDisplay]);

  const handleMouseMove = (e) => {
    setTooltipPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseEnter = (text) => {
    setTooltipText(text);
  };

  const handleMouseLeave = () => {
    setTooltipText("");
  };

  // useEffect(() => {
    // if (!messagesDivRef.current) return;
    // const resizeObserver = new ResizeObserver(() => {
    //   scrollToBottom();
    // });
    // resizeObserver.observe(messagesDivRef.current);
    // return () => resizeObserver.disconnect(); // clean up
  // }, []);
  useEffect(() => {
    if (index === length) {
      scrollToBottom();
    }
  
  }, [length]);

  const handleCallback = useCallback(() => {
    setShowFeedBackIcons(true);
    setGeneratingResponseBool();
  }, [counter, words]);

  const textAreaKeyPress = (event) => {
    var key = window.event.keyCode;

    if (key === 13 && editMessageTextAreaValue !== "" && !event.shiftKey) {
      event.preventDefault();
      editUserMessage();
      return false;
    }
  };

  const editUserMessage = () => {
    setIsMessageEditable(false);
    sendEditMessage(editMessageTextAreaValue, id, index);
    setEditButtonClassName("");
    setEditMessageTextAreaValue(editMessageTextAreaValue);
  };

  const displayAlertBoxFeedback = (type) => {
    if (type === "positif") {
      MySwal.fire({
        customClass: "swal-feedbackPositif",
        showConfirmButton: false,
        html: `<div class="container mt-4">
    <div class="row feedBackTitleDiv">
            <h5> <img alt="" src="images/thumbs up.jpg" style="width:35px;height:35px"> Provide additional feedback </h5>
    </div>
    <div class="row mt-4">
		<textarea class="feedBackTextArea" placeholder="What do you like about the response?"></textarea>
		<div class="col-12 mt-4 submitFeedBackButtonDiv">
			<button class="btn-yes-feedback submitFeedBackButton">Submit Feedback</button>
		</div>
    </div>
</div>`,
        didOpen: async () => {
          const yes = document.querySelector(".btn-yes-feedback");
          const feedbackTextArea = document.querySelector(".feedBackTextArea");

          yes.addEventListener("click", async () => {
            toast.dismiss();
            try {
              toast(
                <div>
                  <div>
                    <Spinner size="sm" /> {"Waiting reponse from the server..."}
                  </div>
                </div>,
                {
                  autoClose: false,
                }
              );

              let feedBackRes = await addFeedBackApi(
                chat_id,
                index,
                "up",
                feedbackTextArea.value
              );

              toast.dismiss();
              Swal.close();
              toast("Thank you for your feedback!");
            } catch (e) {
              toast.dismiss();
              console.log(e);
              toast.error("Could not add the feedback");
            }

            Swal.close();
            // accountCreation();
          });
        },
      }).then((result) => {
        return;
      });
    } else {
      MySwal.fire({
        customClass: "swal-feedbackNegatif",
        showConfirmButton: false,
        html: `<div class="container mt-4">
    <div class="row feedBackTitleDiv">
            <h5> <img alt="" src="images/thumbs down.jpg" style="width:35px;height:35px"> Provide additional feedback </h5>
    </div>
    <div class="row mt-4">
		<textarea class="feedBackTextArea" placeholder="What was the issue with the response? How could it be improved?"></textarea>
		<div class="col-12 mt-4 submitFeedBackButtonDiv">
			<button class="btn-yes-feedback submitFeedBackButton">Submit Feedback</button>
		</div>
    </div>
</div>`,
        didOpen: async () => {
          const yes = document.querySelector(".btn-yes-feedback");
          const feedbackTextArea = document.querySelector(".feedBackTextArea");

          yes.addEventListener("click", async () => {
            toast.dismiss();
            try {
              toast(
                <div>
                  <div>
                    <Spinner size="sm" /> {"Waiting reponse from the server..."}
                  </div>
                </div>,
                {
                  autoClose: false,
                }
              );
              let feedBackRes = await addFeedBackApi(
                chat_id,
                index,
                "down",
                feedbackTextArea.value
              );

              toast.dismiss();
              Swal.close();
              toast("Thank you for your feedback");
            } catch (e) {
              toast.dismiss();
              console.log(e);
              toast.error("Couldn't add the feedback");
            }

            Swal.close();
            // accountCreation();
          });
        },
      }).then((result) => {
        return;
      });
    }
  };

  const copyMessage = async () => {
    await navigator.clipboard.writeText(message);
    setClipBoardCopied(true);
    setTimeout(() => {
      setClipBoardCopied(false);
    }, 3000); // 3000 milliseconds = 3 seconds
  };
  const checkPreviousAnswer = () => {
    checkMyPreviousAnswer();
  };

  return (
    <div onMouseMove={handleMouseMove}>
      {tooltipText !== "" && (
        <div
          style={{
            position: "absolute",
            top: tooltipPosition.y + 10,
            left: tooltipPosition.x + 10,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "white",
            padding: "4px 8px",
            borderRadius: "4px",
            zIndex: 9999,
          }}
        >
          {tooltipText}
        </div>
      )}
      {sendedBy === "user" && (
        <div>
          <div className="row mt-3">
            <div className="col-2"></div>
            <div className="col-8 text-left d-flex">
              {userInfo.firstName && (
                <span className="circle-chat">
                  {userInfo.firstName[0].toUpperCase()}
                </span>
              )}
              <label className="senderLabel">You</label>
            </div>

            <div className="col-1"></div>
          </div>
          <div className="row mt-1">
            <div className="col-2"></div>

            <div className="col-8 text-left d-flex">
              {isMessageEditable && (
                <textarea
                  style={{ whiteSpace: "pre-line" }}
                  className="senderLabelAreaEdit"
                  value={editMessageTextAreaValue}
                  onChange={(event) => {
                    setEditMessageTextAreaValue(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    textAreaKeyPress(event);
                  }}
                ></textarea>
              )}

              {!isMessageEditable && (
                <label
                  style={{
                    whiteSpace: "pre-line",
                    marginLeft: 45,
                    marginTop: 2,
                  }}
                  className="senderLabelMessage"
                >
                  {message}
                </label>
              )}
            </div>

            <div className="col-1"></div>
          </div>
          {isMessageEditable && (
            <div className="row  d-flex">
              <div className="col-2"></div>
              <div
                className="col-1 ms-5 "
                style={{ minHeight: 28, maxHeight: 28 }}
              >
                <button
                  disabled={isGeneratingMessage}
                  onClick={() => {
                    setEditButtonClassName("editMessageButtonClicked");
                    setIsMessageEditable(true);
                  }}
                  className={`editMessageButton ${editButtonClassName}`}
                >
                  <img
                    style={{ height: 20, width: 20 }}
                    src="/images/edit.svg"
                    alt=""
                  />
                </button>
              </div>

              <div className="col-6">
                <button
                  disabled={isGeneratingMessage}
                  onClick={editUserMessage}
                  className="submitMessageEditButton"
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    setIsMessageEditable(false);
                    setEditButtonClassName("");
                    setEditMessageTextAreaValue(message);
                  }}
                  className="cancelMessageEditButton ms-2"
                >
                  Cancel
                </button>
              </div>

              <div className="col-3"></div>
            </div>
          )}
          {!isMessageEditable && (
            <div
              className="row  d-flex"
              style={{ minHeight: 28, maxHeight: 28, marginTop: 4.0 }}
            >
              <div className="col-2"></div>
              <div
                className="col-1 ms-5 "
                style={{ minHeight: 28, maxHeight: 28 }}
              >
                <button
                  onClick={() => {
                    setEditButtonClassName("editMessageButtonClicked");
                    setIsMessageEditable(true);
                  }}
                  className={`editMessageButton ${editButtonClassName}`}
                >
                  <img
                    style={{ height: 20, width: 20 }}
                    src="/images/edit.svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <div ref={messagesDivRef}>
        {sendedBy === "epsiBot" && type === "oneShotDisplay" && (
          <div>
            <div className="row mt-3">
              <div className="col-2"></div>
              <div className="col-8 text-left d-flex">
                <img
                  style={{ height: 30, width: 30 }}
                  alt=""
                  src="/images/epsibotIcon.svg"
                ></img>
                <label className="senderLabel">EpsiBot</label>
              </div>

              <div className="col-1"></div>
            </div>
            <div className="row mt-1">
              <div className="col-2"></div>
              <div className="col-8 text-left d-flex">
                <span
                  className="senderLabelMessage"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {message.split(/\*\*(.*?)\*\*/g).map((part, index) => {
                    if (index % 2 === 0) {
                      return <MathJax.Text inline text={part} />; // Normal text
                    } else {
                      return (
                        <strong key={index}>
                          <MathJax.Text inline text={part} />{" "}
                        </strong>
                      ); // Bold text
                    }
                  })}
                </span>
              </div>

              <div className="col-1"></div>
            </div>

            <div className="row mt-2 d-flex">
              <div className="col-2"></div>
              <div className="col-3 ms-5 ">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    copyMessage();
                  }}
                  onMouseEnter={() => {
                    handleMouseEnter("Copy");
                    setClipBoardHover(true);
                  }}
                  onMouseLeave={() => {
                    handleMouseEnter("");
                    setClipBoardHover(false);
                    setThumbUpHover(false);
                    setThumbDownHover(false);
                    setCheckAnswerHover(false);
                    setTimeout(function () {
                      setCheckAnswerHover(false);
                      setClipBoardHover(false);
                      setThumbUpHover(false);
                      setThumbDownHover(false);
                      handleMouseEnter("");
                    }, 2000);
                  }}
                >
                  {" "}
                  {/* <Icon path={mdiThumbUpOutline} size={1} /> */}
                  {!clipBoardHover && !clipBoardCopied && (
                    <img
                      style={{ height: 20, width: 20 }}
                      src="/images/copy.svg"
                      alt=""
                    />
                  )}
                  {clipBoardHover && !clipBoardCopied && (
                    <img
                      style={{ height: 20, width: 20 }}
                      src="/images/copy-hover.svg"
                      alt=""
                    />
                  )}
                  {clipBoardCopied && (
                    <img
                      style={{ height: 20, width: 20 }}
                      src="/images/copy-ok.svg"
                      alt=""
                    />
                  )}
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    checkPreviousAnswer();
                  }}
                  onMouseEnter={() => {
                    handleMouseEnter("Check Answer");
                    setCheckAnswerHover(true);
                  }}
                  onMouseLeave={() => {
                    handleMouseEnter("");
                    setClipBoardHover(false);
                    setThumbUpHover(false);
                    setThumbDownHover(false);
                    setCheckAnswerHover(false);
                    setTimeout(function () {
                      setCheckAnswerHover(false);
                      setClipBoardHover(false);
                      setThumbUpHover(false);
                      setThumbDownHover(false);
                      handleMouseEnter("");
                    }, 2000);
                  }}
                >
                  {" "}
                  {/* <Icon path={mdiThumbUpOutline} size={1} /> */}
                  {!checkAnswerHover && (
                    <img
                      style={{ height: 20, width: 20 }}
                      src="/images/check.svg"
                      alt=""
                    />
                  )}
                  {checkAnswerHover && (
                    <img
                      style={{ height: 20, width: 20 }}
                      src="/images/check-hover.svg"
                      alt=""
                    />
                  )}
                </span>

                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    displayAlertBoxFeedback("positif");
                  }}
                  onMouseEnter={() => {
                    handleMouseEnter("Like");
                    setThumbUpHover(true);
                  }}
                  onMouseLeave={() => {
                    handleMouseEnter("");
                    setClipBoardHover(false);
                    setThumbUpHover(false);
                    setThumbDownHover(false);
                    setCheckAnswerHover(false);
                    setTimeout(function () {
                      setCheckAnswerHover(false);
                      setClipBoardHover(false);
                      setThumbUpHover(false);
                      setThumbDownHover(false);
                      handleMouseEnter("");
                    }, 2000);
                  }}
                >
                  {" "}
                  {/* <Icon path={mdiThumbUpOutline} size={1} /> */}
                  {!thumbUpHover && (
                    <img
                      style={{ height: 20, width: 20 }}
                      src="/images/thumb-up.svg"
                      alt=""
                    />
                  )}
                  {thumbUpHover && (
                    <img
                      style={{ height: 20, width: 20 }}
                      src="/images/thumb-up-hover.svg"
                      alt=""
                    />
                  )}
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    displayAlertBoxFeedback("negatif");
                  }}
                  onMouseEnter={() => {
                    handleMouseEnter("Dislike");
                    setThumbDownHover(true);
                  }}
                  onMouseLeave={() => {
                    handleMouseEnter("");
                    setClipBoardHover(false);
                    setThumbUpHover(false);
                    setThumbDownHover(false);
                    setCheckAnswerHover(false);
                    setTimeout(function () {
                      setCheckAnswerHover(false);
                      setClipBoardHover(false);
                      setThumbUpHover(false);
                      setThumbDownHover(false);
                      handleMouseEnter("");
                    }, 2000);
                  }}
                >
                  {" "}
                  {/* <Icon className="ms-3" path={mdiThumbDownOutline} size={1} /> */}
                  {!thumbDownHover && (
                    <img
                      style={{ height: 20, width: 20 }}
                      src="/images/thumb-down.svg"
                      alt=""
                    />
                  )}
                  {thumbDownHover && (
                    <img
                      style={{ height: 20, width: 20 }}
                      src="/images/thumb-down-hover.svg"
                      alt=""
                    />
                  )}
                </span>
              </div>

              <div className="col-3"></div>
            </div>
          </div>
        )}

        {sendedBy === "epsiBot" && type === "dynamic" && (
          <div>
            <div className="row mt-3">
              <div className="col-2"></div>
              <div className="col-8 text-left d-flex">
                <img
                  style={{ height: 30, width: 30 }}
                  alt=""
                  src="/images/epsibotIcon.svg"
                ></img>
                <label className="senderLabel">EpsiBot</label>
              </div>

              <div className="col-1"></div>
            </div>
            <div className="row mt-1">
              <div className="col-2"></div>
              <div className="col-8 text-left d-flex">
                <div className="typing-effect-container">
                  <DynamicWordDisplay
                    key={index}
                    expression={{ words: message, type: "regular" }}
                    parentCallback={handleCallback}
                    endDisplayInChild={endDisplayInChild}
                  />

                  {/* <DynamicWordDisplay
                  expression={word[0]}
                  parentCallback={handleCallback}
                /> */}
                </div>
              </div>

              <div className="col-1"></div>
            </div>
            {showFeedBackIcons && (
              <div className="row mt-2 d-flex">
                <div className="col-2"></div>
                <div className="col-3 ms-5 ">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      copyMessage();
                    }}
                    onMouseEnter={() => {
                      handleMouseEnter("Copy");
                      setClipBoardHover(true);
                    }}
                    onMouseLeave={() => {
                      handleMouseEnter("");
                      setClipBoardHover(false);
                      setThumbUpHover(false);
                      setThumbDownHover(false);
                      setCheckAnswerHover(false);
                      setTimeout(function () {
                        setCheckAnswerHover(false);
                        setClipBoardHover(false);
                        setThumbUpHover(false);
                        setThumbDownHover(false);
                        handleMouseEnter("");
                      }, 2000);
                    }}
                  >
                    {" "}
                    {/* <Icon path={mdiThumbUpOutline} size={1} /> */}
                    {!clipBoardHover && !clipBoardCopied && (
                      <img
                        style={{ height: 20, width: 20 }}
                        src="/images/copy.svg"
                        alt=""
                      />
                    )}
                    {clipBoardHover && !clipBoardCopied && (
                      <img
                        style={{ height: 20, width: 20 }}
                        src="/images/copy-hover.svg"
                        alt=""
                      />
                    )}
                    {clipBoardCopied && (
                      <img
                        style={{ height: 20, width: 20 }}
                        src="/images/copy-ok.svg"
                        alt=""
                      />
                    )}
                  </span>

                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      checkPreviousAnswer();
                    }}
                    onMouseEnter={() => {
                      handleMouseEnter("Check Answer");
                      setCheckAnswerHover(true);
                    }}
                    onMouseLeave={() => {
                      handleMouseEnter("");
                      setClipBoardHover(false);
                      setThumbUpHover(false);
                      setThumbDownHover(false);
                      setCheckAnswerHover(false);
                      setTimeout(function () {
                        setCheckAnswerHover(false);
                        setClipBoardHover(false);
                        setThumbUpHover(false);
                        setThumbDownHover(false);
                        handleMouseEnter("");
                      }, 2000);
                    }}
                  >
                    {" "}
                    {/* <Icon path={mdiThumbUpOutline} size={1} /> */}
                    {!checkAnswerHover && (
                      <img
                        style={{ height: 20, width: 20 }}
                        src="/images/check.svg"
                        alt=""
                      />
                    )}
                    {checkAnswerHover && (
                      <img
                        style={{ height: 20, width: 20 }}
                        src="/images/check-hover.svg"
                        alt=""
                      />
                    )}
                  </span>

                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      displayAlertBoxFeedback("positif");
                    }}
                    onMouseEnter={() => {
                      handleMouseEnter("Like");
                      setThumbUpHover(true);
                    }}
                    onMouseLeave={() => {
                      handleMouseEnter("");
                      setClipBoardHover(false);
                      setThumbUpHover(false);
                      setThumbDownHover(false);
                      setCheckAnswerHover(false);
                      setTimeout(function () {
                        setCheckAnswerHover(false);
                        setClipBoardHover(false);
                        setThumbUpHover(false);
                        setThumbDownHover(false);
                        handleMouseEnter("");
                      }, 2000);
                    }}
                  >
                    {" "}
                    {/* <Icon path={mdiThumbUpOutline} size={1} /> */}
                    {!thumbUpHover && (
                      <img
                        style={{ height: 20, width: 20 }}
                        src="/images/thumb-up.svg"
                        alt=""
                      />
                    )}
                    {thumbUpHover && (
                      <img
                        style={{ height: 20, width: 20 }}
                        src="/images/thumb-up-hover.svg"
                        alt=""
                      />
                    )}
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      displayAlertBoxFeedback("negatif");
                    }}
                    onMouseEnter={() => {
                      handleMouseEnter("Dislike");
                      setThumbDownHover(true);
                    }}
                    onMouseLeave={() => {
                      handleMouseEnter("");
                      setClipBoardHover(false);
                      setThumbUpHover(false);
                      setThumbDownHover(false);
                      setCheckAnswerHover(false);
                      setTimeout(function () {
                        setCheckAnswerHover(false);
                        setClipBoardHover(false);
                        setThumbUpHover(false);
                        setThumbDownHover(false);
                        handleMouseEnter("");
                      }, 2000);
                    }}
                  >
                    {" "}
                    {/* <Icon className="ms-3" path={mdiThumbDownOutline} size={1} /> */}
                    {!thumbDownHover && (
                      <img
                        style={{ height: 20, width: 20 }}
                        src="/images/thumb-down.svg"
                        alt=""
                      />
                    )}
                    {thumbDownHover && (
                      <img
                        style={{ height: 20, width: 20 }}
                        src="/images/thumb-down-hover.svg"
                        alt=""
                      />
                    )}
                  </span>
                </div>

                <div className="col-3"></div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default React.forwardRef(MessageSubmitted);
