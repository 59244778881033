import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Ring } from "react-awesome-spinners";
import "./styles/styles.css";
import "./styles/font-styles.css";
import { useSelector } from "react-redux";
import LandingPage from "./LandingPage";
import TermsOfUse from "./TermsOfUse";
import CookiesPolicy from "./CookiesPolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import UpdateProfile from "./UpdateProfile";
import CreditsTerms from "./CreditsTerms";
import Billing from "./Billing";
import Usage from "./Usage";
import BenchMarking from "./BenchMarking";
import ChatBot from "./ChatBot";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import MathJax from "../lib";

function App() {
  let stripePublicKey;

  if (window.location.hostname !== "localhost")
    stripePublicKey =
      "pk_live_51OUwF4AUnbooOyuKZiaotpG4FuPPVAuICRUqBgwcTqGhA1NHZFZrnPAFOREW3hWF3iMsquujCeB0kiZyeos2kleJ006UMscvX1";
  else
    stripePublicKey =
      "pk_test_51OUwF4AUnbooOyuKprZbvQeip8oiOjS5nSJlPfTZ3052VMemvTTFxsW9tWKAJ303PMVB3cUV73ZOHtOJW0HDMmpz00kEboN82R";
  const stripePromise = loadStripe(stripePublicKey, {
    locale: "en",
  });
  const [myUser, setMyUser] = useState(null);
  const [loading, setMyLoading] = useState(true);
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user && !isEmpty(userInfo)) {
        setMyLoading(false);
        setMyUser(user);
      } else {
        setMyLoading(false);
        setMyUser(null);
      }
    });
  }, [userInfo]);
  const isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  return (
    <Elements stripe={stripePromise}>
      <ToastContainer />
      {loading && (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Ring />
        </div>
      )}

      {myUser && !loading && (
        <MathJax.Context
          options={{
            asciimath2jax: {
              useMathMLspacing: true,
              delimiters: [
                ["$", "$"],
                ["\\(", "\\)"],
                ["$$", "$$"],
                ["\\[", "\\]"],
              ],
              preview: "none",
            },
          }}
        >
          <Router>
            <Routes>
              <Route path="/updateProfile" exact element={<UpdateProfile />} />
              <Route path="/billing" exact element={<Billing />} />
              <Route path="/usage" exact element={<Usage />} />
              <Route path="/benchMarking" exact element={<BenchMarking />} />
              <Route path="/termsOfUse" exact element={<TermsOfUse />} />
              <Route path="/cookiesPolicy" exact element={<CookiesPolicy />} />
              <Route path="/privacyPolicy" exact element={<PrivacyPolicy />} />
              <Route path="/creditsTerms" exact element={<CreditsTerms />} />
              <Route path="/" element={<ChatBot />} />
              <Route path="*" element={<ChatBot />} />
            </Routes>
          </Router>
        </MathJax.Context>
      )}
      {!myUser && !loading && (
        <MathJax.Context
          options={{
            asciimath2jax: {
              useMathMLspacing: true,
              delimiters: [
                ["$", "$"],
                ["\\(", "\\)"],
                ["$$", "$$"],
                ["\\[", "\\]"],
              ],
              preview: "none",
            },
          }}
        >
          <Router>
            <Routes>
              <Route path="/benchMarking" exact element={<BenchMarking />} />
              <Route path="/cookiesPolicy" exact element={<CookiesPolicy />} />
              <Route path="/privacyPolicy" exact element={<PrivacyPolicy />} />
              <Route path="/termsOfUse" exact element={<TermsOfUse />} />
              <Route path="/creditsTerms" exact element={<CreditsTerms />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="*" element={<LandingPage />} />
            </Routes>
          </Router>
        </MathJax.Context>
      )}
    </Elements>
  );
}

export default App;
