import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./styles/styles.css";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Helmet from "react-helmet";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as actions from "../actions/usersActions";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { auth, getIdToken } from "../util/firestore";
import { checkPassFormat } from "../util/passwordRegex";
import {
  signup,
  deleteUserByPhone,
  getUserByMailApi,
} from "../domain/myApisConsumption";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import PasswordChecklist from "react-password-checklist";

function LandingPage() {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  // the below line replaces the mapDispatchToProps used by redux
  const dispatch = useDispatch();

  const [loginLoader, setLoginLoader] = useState(false);
  const [showResendEmailVerification, setShowResendEmailVerification] =
    useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showSignUp, setShowSignUp] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [showPasswordCheckList, setShowPasswordCheckList] = useState(false);

  const loginDivStyles = {
    height: showResendEmailVerification ? "420px" : "330px",
    // Add more styles as needed
  };

  const onSignUp = async () => {
    setShowResendEmailVerification(false);
    toast.dismiss();
    setSignUpLoading(true);
    toast.dismiss();

    if (firstName.length < 4) {
      toast.error("First name must at least have 4 chars");
      setSignUpLoading(false);
      return;
    }

    if (lastName.length < 4) {
      toast.error("Last name must at least have 4 chars");
      setSignUpLoading(false);
      return;
    }

    if (email.length <= 1) {
      toast.error("Email field is mandatory");
      setSignUpLoading(false);
      return;
    }

    const phoneNumberPattern = /^[0-9\s+\-\(\)]+$/;
    if (!phoneNumberPattern.test(phone)) {
      toast.error("You have provided an invalid phone number");
      setSignUpLoading(false);
      return;
    }
    if (phone.length < 6) {
      toast.error("Phone number must at least have 6 digits");
      setSignUpLoading(false);
      return;
    }

    if (!checkPassFormat(password)) {
      toast.error("Please check your password", { autoClose: false });
      setSignUpLoading(false);
      return;
    }
    if (passwordConfirmation !== password) {
      toast.error("Password and confirm password aren't matching");
      setSignUpLoading(false);
      return;
    }

    let mySignUpCall = await mySignup({
      firstName: firstName,
      lastName: lastName,
      email: email.toLowerCase(),
      phone: phone,
      password: password,
    });

    // if (mySignUpCall === "userAddedFB") {
    //   toast("user addedFB");
    // } else {
    //   console.log(mySignUpCall);
    //   toast("mySignUpCall");
    // }

    // Swal.close();
    // accountCreation();
  };

  const mySignup = async (user) => {
    let userAddedMongo = false;

    // toast(
    //   <div>
    //     <div>
    //       <Spinner size="sm" /> {"Waiting reponse from the server..."}
    //     </div>
    //   </div>,
    //   {
    //     autoClose: false,
    //   }
    // );
    try {
      let signupApi = await signup(user);

      if (signupApi.data.error) {
        if (signupApi.data.error.keyPattern.phone === 1) {
          toast.dismiss();
          toast.error("The phone number you selected is already in use");
          setSignUpLoading(false);
          return;
        }
      } else userAddedMongo = true;
      try {
        // this toast is used to popup a loading alert

        const myAPIcall = await auth.createUserWithEmailAndPassword(
          user.email,
          user.password
        );

        try {
          await auth.signInWithEmailAndPassword(user.email, user.password);
          console.log("signed in");

          try {
            // this code is to send an email verification for the freshly created account
            await auth.currentUser.sendEmailVerification();
            console.log("email verification sent");
            toast.dismiss();
            setSignUpLoading(false);
            Swal.close();
            setShowSignUp(false);
            accountCreation(user.email);
          } catch (e) {
            toast.dismiss();
            toast(
              "Email verification not sent, please try to sign in with your account to solve this problem"
            );
            Swal.close();
          }
        } catch (e) {
          console.log(e);
          toast.dismiss();
          toast(
            "Email verification not sent, please try to sign in with your account to solve this problem"
          );
        }
      } catch (error) {
        try {
          if (error.code === "auth/email-already-in-use") {
            setSignUpLoading(false);
            toast.dismiss();
            toast("Email already in use");
          } else if (error.code === "auth/invalid-email") {
            await deleteUserByPhone(user.phone, user.email);
            toast.dismiss();
            toast.error("Invalid email adress");
            setSignUpLoading(false);
          } else if (error.code === "auth/weak-password") {
            await deleteUserByPhone(user.phone, user.email);
            toast.dismiss();
            toast.error("Weak password");
            setSignUpLoading(false);
          } else {
            await deleteUserByPhone(user.phone, user.email);
            toast.dismiss();
            console.log(error.code);
            toast.error(
              "An unexpected error has occured, please signal this error with the e-100 code"
            );
            setSignUpLoading(false);
          }
        } catch (e) {
          toast.dismiss();
          toast.error(
            "An unexpected error has occured, please signal this error with the e-200 code"
          );
          setSignUpLoading(false);
        }
      }

      console.log(signupApi);
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("A server side error has occured, please try again later");
      setSignUpLoading(false);
      return;
    }
  };

  const passwordResetSuccess = (email) => {
    MySwal.fire({
      customClass: "swal-forgot-pass-success",
      showConfirmButton: false,
      html: `<h5 class="signUpAndPassResetTitle">Password reset</h5>
      
      <div class="container">
        <div class="row">
          <div class="col-12 border border-left-0 border-right-0 border-black text-center"></div>
        </div>
      </div>
     
    
      <div class="container mt-4">
        <div class="row mt-5">
        <div class="col-1"></div>
        <div class="col-10 text-center">
        <p class="activationLinkText">A password reset link has been sent to ${email} </p>
        <p class="activationLinkText">Click the link to set a new password for your account</p>
      </div>
      <div class="col-1"></div>
        </div>



        <div class="row mt-5">
        <div class="col-1"></div>
        <div class="col-10 text-center">
        <button class="closingButtonClass landingPageLoginButton">Go to homepage</button>
      </div>
      <div class="col-1"></div>
        </div>
      </div>`,
      didOpen: () => {
        const yes = document.querySelector(".closingButtonClass");
        yes.addEventListener("click", () => {
          Swal.close();
          navigate("/");
        });
      },
    }).then((result) => {
      return;
    });
  };

  const accountCreation = (email) => {
    MySwal.fire({
      customClass: "swal-height-activationLink",
      showConfirmButton: false,
      html: `<h5 class="signUpAndPassResetTitle">Sign up</h5>
      
      <div class="container">
        <div class="row">
          <div class="col-12 border border-left-0 border-right-0 border-black text-center"></div>
        </div>
      </div>
     
    
      <div class="container mt-4">
       
        <div class="row">
        <div class="col-1"></div>
          <div class="col-10 text-center">
            <label class="successCreationLabel">Your account has successfully been created</label>
          </div>
          <div class="col-1"></div>
        </div>
       
        <div class="row mt-5">
        <div class="col-1"></div>
        <div class="col-10 text-center">
        <label class="activationLinkText">An activation link has been sent to ${email}
        Click the link to activate your account, then close this dialog box to login.</label>
      </div>
      <div class="col-1"></div>
        </div>



        <div class="row mt-5">
        <div class="col-1"></div>
        <div class="col-10 text-center">
        <button class="landingPageCreationAccountButton closingButtonClass">Close and log in</button>
      </div>
      <div class="col-1"></div>
        </div>
      </div>`,
      didOpen: () => {
        const yes = document.querySelector(".closingButtonClass");

        yes.addEventListener("click", () => {
          Swal.close();
        });
      },
    }).then((result) => {
      return;
    });
  };
  const forgotPass = () => {
    MySwal.fire({
      customClass: "swal-height",
      showConfirmButton: false,
      html: `<h5 class="signUpAndPassResetTitle">Password reset</h5>
      
      <div class="container">
        <div class="row">
          <div class="col-12 border border-left-0 border-right-0 border-black text-center"></div>
        </div>
      </div>
     
    
      <div class="container mt-4">
       
        <div class="row">
        <div class="col-1"></div>
          <div class="col-10 text-center">
            <input type="email"  class="mt-4 signUpInputs otherThanNamesSignUpInputs" id="emailAdressForResetPass" placeholder="Email address"></input>
          </div>
          <div class="col-1"></div>
        </div>
       
        <div class="row mt-3">
        <div class="col-1"></div>
          <div class="col-6 text-center">
      
            <button style="height:140%;cursor:"pointer"" class="btn-yes-link landingPageButton landingPageConfirmSignUpButton" id="forgotPassButtonSend">Send me a link</button>
          </div>
          <div class="col-4 text-center">
      
            <button style="height:140%" class="btn-cancel-link landingPageCancelSignUpButton" >Cancel</button>
          </div>
          <div class="col-1"></div>
        </div>
      </div>`,
      didOpen: () => {
        const yes = document.querySelector(".btn-yes-link");
        const no = document.querySelector(".btn-cancel-link");
        const forgotPassButton = document.getElementById(
          "forgotPassButtonSend"
        );

        yes.addEventListener("click", async () => {
          toast.dismiss();
          const emailInput = document.getElementById(
            "emailAdressForResetPass"
          ).value;

          if (emailInput.length < 1) {
            toast.error("Please fill in the email field");
          } else {
            forgotPassButton.disabled = true;

            try {
              await auth.sendPasswordResetEmail(emailInput);

              toast.dismiss();
              Swal.close();
              //  opens the swal success reset pass box
              passwordResetSuccess(emailInput);
            } catch (error) {
              toast.dismiss();
              console.error(error.code);
              forgotPassButton.disabled = false;

              if (error.code === "auth/user-not-found") {
                toast.error(
                  "There is no user subscribed with this email adress in our website",
                  {
                    autoClose: false,
                  }
                );
              } else if (error.code === "auth/invalid-email") {
                toast.error("You have typed an invalid email adress", {
                  autoClose: false,
                });
              } else if (error.code === "auth/too-many-requests") {
                toast.error(
                  "You've already sent too many requests. Please try again later",
                  {
                    autoClose: false,
                  }
                );
              } else {
                toast.error(
                  "An unexpected error has occured. Please try again later. If this error persists, please feel free to report this error as error code e-1000",
                  {
                    autoClose: false,
                  }
                );
              }
            }
          }
        });

        no.addEventListener("click", () => {
          toast.dismiss();
          Swal.close();
        });
      },
    }).then((result) => {
      toast.dismiss();
      return;
    });
  };
  const goToBenchMarking = () => {
    navigate("/benchMarking");
  };

  const login = async () => {
    setShowResendEmailVerification(false);
    toast.dismiss();
    let myEmail = loginEmail.toLowerCase();

    let myPassword = loginPassword;

    if (myEmail === "" || myPassword === "") {
      toast.error("Enter your email and password to login");
      return;
    }

    setLoginLoader(true);
    let signInCase = "";
    let emailVerified;

    try {
      const userRecord = await auth.signInWithEmailAndPassword(
        myEmail,
        myPassword
      );
      signInCase = "signedInFB";

      emailVerified = userRecord.user.emailVerified;
    } catch (error) {
      signInCase = error.code;
    }

    if (signInCase === "signedInFB" && emailVerified) {
      await getUserByMailFromDB(myEmail);

      return;
    } else if (signInCase === "signedInFB" && !emailVerified) {
      setShowResendEmailVerification(true);
      setLoginLoader(false);
      toast.error(
        "Please activate your account via your email adress. If you wish to resend an activation link, click on the link below the login button",
        {
          autoClose: false,
        }
      );
      return;
    } else if (signInCase === "auth/invalid-email") {
      setLoginLoader(false);
      toast.error("You have provided an invalid email adress", {
        autoClose: false,
      });
    } else if (signInCase === "auth/network-request-failed") {
      setLoginLoader(false);
      toast.error("Please verify your internet connection and try again", {
        autoClose: false,
      });
    } else if (signInCase === "auth/user-not-found") {
      setLoginLoader(false);
      toast.error("You have typed wrong credentials. Please try again", {
        autoClose: false,
      });
    } else if (signInCase === "auth/invalid-credential") {
      setLoginLoader(false);
      toast.error("You have typed wrong credentials. Please try again", {
        autoClose: false,
      });
    } else if (signInCase === "auth/wrong-password") {
      setLoginLoader(false);
      toast.error("You have typed wrong credentials. Please try again", {
        autoClose: false,
      });
    } else if (signInCase === "auth/too-many-requests") {
      setLoginLoader(false);
      toast.error(
        "You've sent too many login requests. Try again later please!",
        {
          autoClose: false,
        }
      );
    } else {
      setLoginLoader(false);
      toast.error(
        "You've sent too many login requests. Try again later please!",
        {
          autoClose: false,
        }
      );
    }
  };

  const handlePassChange = (event) => {
    setLoginPassword(event.target.value);
  };
  const handleEmailChange = (event) => {
    setLoginEmail(event.target.value);
  };
  const resendEmailVerification = async () => {
    toast.dismiss();
    setLoginLoader(true);
    let currentUser;

    currentUser = await auth.currentUser;

    try {
      await currentUser.sendEmailVerification();
      toast("Your activation mail has been resent succesfully!");
      setShowResendEmailVerification(false);
      setLoginLoader(false);
    } catch (error) {
      console.log(error);
      toast.error(
        "An error has occured while trying to resend you a verification email, please try again!"
      );
      setLoginLoader(false);
    }
  };

  const getUserByMailFromDB = async (mail) => {
    let idToken = null;
    let responseGetUserByMailApi;
    try {
      idToken = await getIdToken();
      responseGetUserByMailApi = await getUserByMailApi(mail, idToken);

      if (responseGetUserByMailApi.status === 200) {
        if (responseGetUserByMailApi.data.message === "success") {
          setLoginLoader(false);
          dispatch(
            actions.initialiseUser({
              id: responseGetUserByMailApi.data.user._id,
              firstName: responseGetUserByMailApi.data.user.firstName,
              lastName: responseGetUserByMailApi.data.user.lastName,
              email: responseGetUserByMailApi.data.user.email,
              phone: responseGetUserByMailApi.data.user.phone,
            })
          );
          // window.location.reload(false);
        } else {
          setLoginLoader(false);
          toast.error(
            "An unexpected error has occured, if this persists, please report this error as error code e-600",
            {
              autoClose: false,
            }
          );
        }
      } else {
        setLoginLoader(false);
        toast.error(
          "A server side error occured, please try again later, if this persists, try to report this with error code e-500",
          {
            autoClose: false,
          }
        );
      }
    } catch (e) {
      console.log(e);
      setLoginLoader(false);
      toast.error(
        "A server side error occured, please try again later, if this persists, try to report this with error code e-500",
        {
          autoClose: false,
        }
      );
    }
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleEmailChangeInSignUp = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handlePasswordConfirmChange = (event) => {
    setPasswordConfirmation(event.target.value);
  };

  const handleLoginKeyDown = (event) => {
    if (event.key === "Enter") {
      // Enter key was pressed
      login();
    }
  };

  const navigateLandingPage = () => {
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>EpsiBot: the advanced math chatbot</title>
      </Helmet>
      <div style={{ minWidth: 1000 }}>
        <div className=" container-fluid row">
          <CModal
            alignment={"center"}
            visible={showSignUp}
            onClose={() => setShowSignUp(false)}
            backdrop={"static"}
            className="custom-modal"
          >
            <CModalHeader onClose={() => setShowSignUp(false)}>
              <CModalTitle
                style={{ textAlign: "center", flex: 1 }}
                id="LiveDemoExampleLabel"
              >
                Sign up
              </CModalTitle>
            </CModalHeader>
            <CModalBody>
              <>
                <div className="container mt-4">
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 text-center">
                      <input
                        type="text"
                        className="signUpInputs otherThanNamesSignUpInputs"
                        placeholder="First name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                      ></input>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 text-center">
                      <input
                        type="text"
                        className="mt-4 signUpInputs otherThanNamesSignUpInputs"
                        placeholder="Last name"
                        value={lastName}
                        onChange={handleLastNameChange}
                      ></input>
                    </div>
                    <div className="col-1"></div>
                  </div>

                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 text-center">
                      <input
                        type="email"
                        className="mt-4 signUpInputs otherThanNamesSignUpInputs"
                        placeholder="Email address"
                        value={email}
                        onChange={handleEmailChangeInSignUp}
                      ></input>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 text-center">
                      <input
                        type="text"
                        className="mt-4 signUpInputs otherThanNamesSignUpInputs"
                        placeholder="Phone (international format)"
                        value={phone}
                        onChange={handlePhoneChange}
                      ></input>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 text-center">
                      <input
                        type="password"
                        className="mt-4 signUpInputs otherThanNamesSignUpInputs"
                        value={password}
                        onChange={handlePasswordChange}
                        onFocus={() => {
                          setShowPasswordCheckList(true);
                        }}
                        placeholder="Password"
                      ></input>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 text-center">
                      <input
                        type="password"
                        className="mt-4 signUpInputs otherThanNamesSignUpInputs"
                        value={passwordConfirmation}
                        onChange={handlePasswordConfirmChange}
                        onFocus={() => {
                          setShowPasswordCheckList(true);
                        }}
                        placeholder="Confirm password"
                      ></input>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  {showPasswordCheckList && (
                    <div className="row">
                      <div className="col-1"></div>
                      <div className="col-10 text-center">
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "lowercase",
                            "capital",
                            "match",
                          ]}
                          minLength={8}
                          value={password}
                          valueAgain={passwordConfirmation}
                        />
                      </div>
                      <div className="col-1"></div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 text-center">
                      <label className="mt-4 privatePolicyLabel">
                        By clicking “Sign up”, you agree to our{" "}
                        <a href="termsOfUse" className="termsOfPolicyAnchor">
                          Terms of use,
                        </a>{" "}
                        <a href="privacyPolicy" className="termsOfPolicyAnchor">
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a href="cookiesPolicy" className="termsOfPolicyAnchor">
                          Cookies Policy
                        </a>
                        . You may receive marketing and sales emails from us and
                        can opt out any time.
                      </label>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-1"></div>
                    <div className="col-6 text-center">
                      <button
                        id="signupButton"
                        className="btn-yes-signup landingPageButton landingPageConfirmSignUpButton"
                        onClick={onSignUp}
                        disabled={signUpLoading}
                      >
                        {signUpLoading && <Spinner />}
                        {!signUpLoading && <label>Sign up</label>}
                      </button>
                    </div>
                    <div className="col-4 text-center">
                      <button
                        id="cancelSignupButton"
                        className="btn-cancel-signup landingPageCancelSignUpButton"
                        onClick={() => {
                          setShowSignUp(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </div>
              </>
            </CModalBody>
          </CModal>

          <div className="col-2">
            {" "}
            <div
              style={{
                height: "63px",
                width: "100%",
                backgroundColor: "#FFFFFF",
              }}
            >
              <img
                alt=""
                style={{ cursor: "pointer" }}
                src={"/images/blue background logo.svg"}
                onClick={navigateLandingPage}
              />
            </div>
          </div>
          <div className="col-8"></div>
          <div className="col-2" style={{ marginTop: "15px", textAlign:"right" , paddingRight:"20px" }}>
            <button
              id="signupButton"
              className="btn-yes-signup landingPageButton landingPageConfirmSignUpButton"
              onClick={() => {
                setShowSignUp(true);
              }}
              disabled={loginLoader}
              style={{ width: "150pt" }}
            >
              Sign up
            </button>
          </div>
        </div>

        <div>
          <div className="container-fluid" style= {{ paddingTop:"50px" }}>
            <div className="row">
              <div className="col-2"></div>
              <div className="col-5">
                <div className="row">
                  <div className="col-12">
                    <label className="labelAdvanced">
                      EpsiBot: the advanced math assistant
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label className="destinedForLabel">
                      For students, professors, and math lovers
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label className="smallerLabelLandingPage">
                      To answer your math questions and help you learn, teach,
                      or enjoy math
                    </label>
                  </div>
                </div>
                <br></br>
                <div className="row ">
                  <div className="col-11 loginSignUpDiv">
                    {" "}
                    <div
                      className="col-6"
                      style={{
                        borderRight: "1px solid 	#90A0BD",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="row">
                        <div className="col-12 text-center">
                          <label className="loginSignUpTitleLabel">Login</label>
                        </div>
                        <div className="col-12 text-center mt-2">
                          <div style={{ position: "relative" }}>
                            <img
                              src="images/at.svg"
                              alt=""
                              style={{
                                marginLeft: "14px",
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                height: "10px",
                                width: "10px",
                              }}
                            />
                            <input
                              className="passwordAndEmailInputs2"
                              placeholder="Email address"
                              value={loginEmail}
                              onChange={handleEmailChange}
                              onKeyDown={handleLoginKeyDown}
                              type="email"
                              style={{ paddingLeft: "30px" }} // Add padding to the left to make space for the image
                            />
                          </div>
                        </div>
                        <div className="col-12 text-center mt-2">
                          <div style={{ position: "relative" }}>
                            <img
                              src="images/lock.svg"
                              alt=""
                              style={{
                                marginLeft: "14px",
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                height: "10px",
                                width: "10px",
                              }}
                            />
                            <input
                              className="passwordAndEmailInputs2"
                              placeholder="Password"
                              value={loginPassword}
                              onChange={handlePassChange}
                              onKeyDown={handleLoginKeyDown}
                              type="password"
                              style={{ paddingLeft: "30px" }}
                            ></input>
                          </div>
                        </div>
                        <div className="col-12 text-center mt-2">
                          <button
                            className="landingPageLoginButton"
                            onClick={login}
                            disabled={loginLoader}
                          >
                            {loginLoader && <Spinner />}
                            {!loginLoader && (
                              <label
                                style={{ cursor: "pointer" }}
                                className="submitButtonText cursorToPointer"
                              >
                                Login
                              </label>
                            )}
                          </button>
                        </div>
                        <div className="col-12 text-center mt-2">
                          <label
                            className="labelForgotPass"
                            onClick={forgotPass}
                          >
                            Forgot your password?
                          </label>
                        </div>

                        {showResendEmailVerification && (
                          <div className="col-12 text-center mt-2">
                            <label
                              className="labelForgotPass"
                              onClick={resendEmailVerification}
                            >
                              Resend email verification?
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="col-6"
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="row">
                        <div className="col-12 text-center">
                          <label className="loginSignUpTitleLabel">
                            Sign up
                          </label>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="col-12 text-center" style = {{width:"95%", textAlign:"center"}}>
                          <label className="createNewAccountLabel">
                            Create a new account  <br></br> to start chatting!
                          </label>
                        </div>
                        <br></br>
                        <br></br>

                        <br></br>
                        <div className="col-12 text-center mt-2 ms-1">
                          <button
                            id="signupButton"
                            className="btn-yes-signup landingPageButton landingPageConfirmSignUpButton"
                            onClick={() => {
                              setShowSignUp(true);
                            }}
                            disabled={loginLoader}
                            style={{ width: "95%", marginTop: "4.5px" }}
                          >
                            Sign up
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2 text-end">
                <img
                  style={{ width: "400px", height: "446px" }}
                  src="images/EpsiBot.jpg"
                  alt=""
                />
              </div>
              <div className="col-2"></div>
            </div>
          </div>
        </div>
        <br></br>

        {/* <div
          style={{
            backgroundImage: 'url("images/bg 1.svg")',
            height: 550,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: 550,
          }}
        >
         
          <div>‎ </div>
          <div style={{ marginTop: "2%" }}>
            <div className="container">
              <div className="row">
                <div style={{ paddingLeft: 15 }} className="col-8">
                  <div className="row" style={{ marginTop: 24 }}>
                    <div style={{ paddingLeft: 58 }} className="col-12">
                      <label className="labelTitleIntroductionBold">
                        Epsibot: the advanced math assistant
                      </label>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 24 }}>
                    <div style={{ paddingLeft: 58 }} className="col-12">
                      <label className="labelSecondTitleIntroduction">
                        For students, professors, and professionals
                      </label>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 24 }}>
                    <div style={{ paddingLeft: 58 }} className="col-12">
                      <label className="labelThirdTitleIntroduction">
                        To answer all your questions and help you save time and
                        energy
                      </label>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: 24 }}>
                    <div style={{ paddingLeft: 58 }} className="col-12">
                      <button
                        className="landingPageButton"
                        onClick={goToBenchMarking}
                        disabled={loginLoader}
                      >
                        Sample Chats
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="col-10">
                    <div className="loginDiv" style={loginDivStyles}>
                      <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10 mt-4">
                          <input
                            className="passwordAndEmailInputs"
                            placeholder="Email address"
                            value={loginEmail}
                            onChange={handleEmailChange}
                            onKeyDown={handleLoginKeyDown}
                            type="email"
                          ></input>
                        </div>
                        <div className="col-1"></div>
                      </div>
                      <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10 mt-4">
                          <input
                            className="passwordAndEmailInputs"
                            placeholder="Password"
                            value={loginPassword}
                            onChange={handlePassChange}
                            onKeyDown={handleLoginKeyDown}
                            type="password"
                          ></input>
                        </div>
                        <div className="col-1"></div>
                      </div>
                      <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10 mt-4 text-center">
                          <button
                            className="landingPageLoginButton"
                            onClick={login}
                            disabled={loginLoader}
                          >
                            {loginLoader && <Spinner />}
                            {!loginLoader && (
                              <label
                                style={{ cursor: "pointer" }}
                                className="submitButtonText cursorToPointer"
                              >
                                Login
                              </label>
                            )}
                          </button>
                        </div>
                        <div className="col-1"></div>
                      </div>
                      {showResendEmailVerification && (
                        <div className="row">
                          <div className="col-1"></div>
                          <div className="col-10 mt-4 text-center">
                            <label
                              className="labelForgotPass"
                              onClick={resendEmailVerification}
                            >
                              Resend email verification?
                            </label>
                          </div>
                          <div className="col-1"></div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10 mt-4 text-center">
                          <label
                            className="labelForgotPass"
                            onClick={forgotPass}
                          >
                            Forgot your password?
                          </label>
                        </div>
                        <div className="col-1"></div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3"></div>
                        <div className="col-6 line"></div>
                       
                        <div className="col-3"></div>
                      </div>
                      <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10 mt-4 text-center">
                          <button
                            style={{ width: "100%" }}
                            className="landingPageButton"
                            onClick={() => {
                              setShowSignUp(true);
                            }}
                            disabled={loginLoader}
                          >
                            Sign up
                          </button>
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div>
          <div className=" benchMarkingDiv container-fluid">
            <div className="container">
              {" "}
              <div className="row ">
                <div className="col-2"></div>
                <div className="col-8 text-center epsiPerformanceLabel mt-5">
                  EpsiBot outperforms ChatGPT-4 and Gemini on exams from top
                  universities
                </div>

                <div className="col-2"></div>
              </div>
            </div>

            <div className="row text-center" style={{ paddingTop: 45 }}>
              <div className="col-2"></div>
              <div className="col-8">
                <div className="row">
                  {" "}
                  <div className="col-4">
                    <img className="imageSize" src="images/bMgpt.svg" alt=""></img>
                  </div>
                  <div className="col-4">
                    <img className="imageSize" src="images/bMgemini.svg" alt=""></img>
                  </div>
                  <div className="col-4">
                    <img className="imageSize" src="images/bMepsi.svg" alt=""></img>
                  </div>
                </div>{" "}
              </div>

              <div className="col-2"></div>
            </div>
            <div className="row text-center" style={{ paddingTop: 45 }}>
              <div className="col-1"></div>
              <div className="col-3"></div>
              <div className="col-3" style={{ marginLeft: 30 }}>
                <div>
                  <button
                    className="answerCompButton"
                    onClick={goToBenchMarking}
                    disabled={loginLoader}
                  >
                    See answers comparison
                  </button>
                </div>
              </div>
              <div className="col-3" style={{ marginLeft: 30 }}></div>
              <div className="col-1"></div>
            </div>
          </div>

          <img
            style={{ height: "100%", width: "100%" }}
            alt=""
            src="images/student_professor.svg"
          ></img>

          {/* <div
            style={{
              backgroundImage: 'url("images/student_professor.svg")',
              height: 700,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width:"100%"
              
            }}
          ></div> */}

          <br></br>
          <div className="epsiTopics container-fluid">
            <div className="row mt-5">
              <div className="col-2"></div>
              <div className="col-8 text-center">
                <label className="topicsRangeLabel">
                  EpsiBot can answer questions about various math topics
                </label>
              </div>
              <div className="col-2"></div>
            </div>
            <div className="row mt-5">
              <div className="col-2"></div>
              <div className="col-2 text-center">
                <img src="images/topic01.svg" alt="" />
              </div>
              <div className="col-2 text-center">
                <img src="images/topic02.svg" alt="" />
              </div>
              <div className="col-2 text-center">
                <img src="images/topic03.svg" alt="" />
              </div>
              <div className="col-2 text-center">
                <img src="images/topic04.svg" alt="" />
              </div>
              <div className="col-2"></div>
            </div>
            <div className="row mt-5">
              <div className="col-2"></div>
              <div className="col-2 text-center">
                <img src="images/topic05.svg" alt="" />
              </div>
              <div className="col-2 text-center">
                <img src="images/topic06.svg" alt="" />
              </div>
              <div className="col-2 text-center">
                <img src="images/topic07.svg" alt="" />
              </div>
              <div className="col-2 text-center">
                <img src="images/topic08.svg" alt="" />
              </div>
              <div className="col-2"></div>
            </div>
          </div>
          <br></br>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default LandingPage;
