import { useState, useCallback } from "react";
import Header from "./Header";
import SideMenus from "./SideMenus";
import SettingsHeader from "./SettingsHeader";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import * as actions from "../actions/usersActions";
import { auth, getIdToken } from "../util/firestore";
import firebase from "firebase/compat/app";
import { editProfileById, deleteUserById } from "../domain/myApisConsumption";
import { checkPassFormat } from "../util/passwordRegex";
import Helmet from "react-helmet";

function UpdateProfile() {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo) || {};
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState(userInfo.firstName);
  const [lastName, setLastName] = useState(userInfo.lastName);
  const [loadingStatus, setLoadingStatus] = useState("none");

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const email = userInfo.email;
  const id = userInfo.id;
  const phone = userInfo.phone;

  // useEffect(() => {}, [userInfo]);
  const deleteAccount = () => {
    toast.dismiss();
    MySwal.fire({
      customClass: "swal-update-profile",
      showConfirmButton: false,
      html: `<h5 class="signUpAndPassResetTitle">Delete account</h5>
      
      <div class="container">
        <div class="row">
          <div class="col-12 border border-left-0 border-right-0 border-black text-center"></div>
        </div>
      </div>
     
    
      <div class="container mt-4">
        
        <div class="row">
        <div class="col-1"></div>
          <div class="col-10 text-center">
            <label class="activationLinkText">Are you sure you want to delete your account?
            All your information will be lost, including discussions history.
            You will also lose the remaining unused credit.</label>
          </div>
          <div class="col-1"></div>
        </div>
           
        <div class="row mt-3">
        <div class="col-1"></div>
          <div class="col-6 text-center">
      
            <button class="btn-yes-delete landingPageButton landingPageConfirmSignUpButton">Delete account</button>
          </div>
          <div class="col-4 text-center">
      
            <button class="btn-cancel-delete landingPageCancelSignUpButton" >Cancel</button>
          </div>
          <div class="col-1"></div>
        </div>
      </div>`,
      didOpen: () => {
        const yes = document.querySelector(".btn-yes-delete");
        const no = document.querySelector(".btn-cancel-delete");

        yes.addEventListener("click", () => {
          Swal.close();
          confirmDeleteAccount();
        });

        no.addEventListener("click", () => {
          Swal.close();
        });
      },
    }).then((result) => {
      return;
    });
  };

  const confirmDeleteAccount = async () => {
    setLoadingStatus("delete account");

    let idToken = null;
    let responseDeleteUserById;
    try {
      idToken = await getIdToken();
      responseDeleteUserById = await deleteUserById(
        {
          id: id,
          phone: phone,
          email: email,
          firstName: firstName,
          lastName: lastName,
        },
        idToken
      );
      console.log(responseDeleteUserById);

      if (responseDeleteUserById.data.message === "user deleted") {
        setLoadingStatus("none");
        await Swal.fire({
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: "swalConfirmButton",
          },
          text: "Your account has been successfully deleted",
        }).then((result) => {
          logout();
        });
      } else if (
        responseDeleteUserById.data.message === "error" ||
        responseDeleteUserById.data.message === "user not deleted"
      ) {
        setLoadingStatus("none");
        toast.error(
          "We couldn't delete your account for now, please try again later",
          {
            autoClose: false,
          }
        );
        return;
      }
      // case mongodb delete only
      else if (
        responseDeleteUserById.data.message === "user deleted in mongodb only"
      ) {
        setLoadingStatus("none");
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your account data has been PARTIALLY deleted because of technical problems, if you want to delete all your related account informations, feel free to contact us with the error code da-100 and provide us with your email adress",
        }).then((result) => {
          logout();
        });
      } else if (responseDeleteUserById.data.message === "error stripe") {
        setLoadingStatus("none");
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your account data has been PARTIALLY deleted because of technical problems, if you want to delete all your related account informations, feel free to contact us with the error code da-200 and provide us with your email adress",
        }).then((result) => {
          logout();
        });
      }
    } catch (e) {
      console.log(e);
      setLoadingStatus("none");
      toast.error(
        "A server side error occured, please try again later. If this persists, report it with error code e-1400",
        {
          autoClose: false,
        }
      );
    }
  };

  // const confirmDeleteAccount = () => {
  //   MySwal.fire({
  //     customClass: "swal-height-activationLink",
  //     showConfirmButton: false,
  //     html: `<h5 class="signUpAndPassResetTitle">Delete account</h5>

  //     <div class="container">
  //       <div class="row">
  //         <div class="col-12 border border-left-0 border-right-0 border-black text-center"></div>
  //       </div>
  //     </div>

  //     <div class="container mt-4">

  //       <div class="row">
  //       <div class="col-1"></div>
  //         <div class="col-10 text-center">
  //           <label class="successCreationLabel">Your account has been deleted</label>
  //         </div>
  //         <div class="col-1"></div>
  //       </div>

  //       <div class="row mt-5">
  //       <div class="col-1"></div>
  //       <div class="col-10 text-center">
  //       <label class="activationLinkText">A confirmation email has been sent to xxxxx@email.com</label>
  //     </div>
  //     <div class="col-1"></div>
  //       </div>

  //       <div class="row mt-5">
  //       <div class="col-1"></div>
  //       <div class="col-10 text-center">
  //       <button class="landingPageLoginButton closingButtonClass">Go to homepage</button>
  //     </div>
  //     <div class="col-1"></div>
  //       </div>
  //     </div>`,
  //     didOpen: () => {
  //       const yes = document.querySelector(".closingButtonClass");
  //       yes.addEventListener("click", () => {
  //         navigate("/");
  //         Swal.close();
  //       });
  //     },
  //   }).then((result) => {
  //     return;
  //   });
  // };

  const logout = async () => {
    try {
      dispatch(actions.logOutUserAction({}));
      await auth.signOut();
    } catch (e) {
      console.log(e);
      toast(
        "An unexpected error occured while trying to logout, please try again! If this persists, report it with the error code e-800",
        { autoClose: false }
      );
    }

    navigate("/");
  };

  const applyPasswordChanges = async () => {
    toast.dismiss();

    let newPass = newPassword;
    let newPassConfirm = newPasswordConfirm;
    let oldPass = oldPassword;
    let user, credential;

    if (newPass === "" || oldPass === "" || newPassConfirm === "") {
      toast.error("You need to fill the passwords fields");
      return;
    }
    if (newPass !== newPassConfirm) {
      toast.error("Password and password confirm must be matching");
      return;
    }

    if (!checkPassFormat(newPass)) {
      toast.error(
        <div>
          <div>
            Passwords must be at least 8 characters in length, but can be much
            longer.
          </div>
          <div>Passwords must contain:</div>

          <div>a minimum of 1 lower case letter </div>
          <div>a minimum of 1 upper case letter </div>
          <div>a minimum of 1 numeric character </div>
          <div>a minimum of 1 special character</div>
        </div>,
        { autoClose: false }
      );
      return;
    }
    setLoadingStatus("edit pass");

    try {
      user = auth.currentUser;

      try {
        credential = firebase.auth.EmailAuthProvider.credential(email, oldPass);

        try {
          await user.reauthenticateWithCredential(credential);

          try {
            await user.updatePassword(newPass);
            setLoadingStatus("none");
            toast("Password updated succesfully!");
          } catch (error) {
            setLoadingStatus("none");
            console.error(error);
            toast.error(
              "Couldn't update your password, try loging out and then login in to solve this problem!",
              { autoClose: false }
            );
          }
        } catch (error) {
          console.error(error);
          setLoadingStatus("none");
          if (error.code === "auth/wrong-password") {
            toast.error(
              "You have typed a password that is not matching yours. Please try again!",
              { autoClose: false }
            );
          } else if (error.code === "auth/user-mismatch") {
            toast.error(
              "An internal error has occured. Try disconnecting and reconnecting to solve this problem",
              { autoClose: false }
            );
          } else if (error.code === "auth/too-many-requests") {
            toast.error(
              `You've sent too many requests with a wrong password. Please try again later!`,
              { autoClose: false }
            );
          }
        }
      } catch (e) {
        console.log(e);
        console.log("error while initialising const credential for fb");
        setLoadingStatus("none");
        toast.error(
          "An unexpected error has occured, please try loging out and login in to solve this problem!",
          { autoClose: false }
        );
      }
    } catch (e) {
      console.log("couldnt get current user from fb");
      setLoadingStatus("none");
      toast.error(
        "An unexpected error has occured, please try loging out and login in to solve this problem!",
        { autoClose: false }
      );
    }
  };

  const applyProfileChanges = useCallback(async () => {
    let fn = firstName;
    let ln = lastName;
    toast.dismiss();
    if (fn === userInfo.firstName && ln === userInfo.lastName) {
      toast.error("You didn't change your profile informations");
      return;
    } else if (fn.length < 2 || ln.length < 2) {
      toast.error("First name and last name must be at least 2 characters");
      return;
    } else {
      try {
        setLoadingStatus("update profile");
        let idToken = null;
        idToken = await getIdToken();
        let updateProfileResponse = await editProfileById(idToken, fn, ln, id);
        if (
          updateProfileResponse.data.message === "user modified succesfully"
        ) {
          dispatch(
            actions.updateNameLastNameAction({
              firstName: fn,
              lastName: ln,
            })
          );
          setLoadingStatus("none");
          toast("Profile info updated succesfully", {
            autoClose: false,
          });
        } else {
          setLoadingStatus("none");
          toast(
            "An unexpected error has occured. If this persists, report it with the error code e-1100",
            {
              autoClose: false,
            }
          );
        }
      } catch (e) {
        console.log(e);
        setLoadingStatus("none");
        toast(
          "An unexpected error has occured. If this persists, report it with the error code e-1200",
          {
            autoClose: false,
          }
        );
      }
    }
  }, [
    dispatch,
    firstName,
    id,
    lastName,
    userInfo.firstName,
    userInfo.lastName,
  ]);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleNewPasswordConfirmChange = (event) => {
    setNewPasswordConfirm(event.target.value);
  };
  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Epsibot update profile</title>
      </Helmet>
      <div style={{ minWidth: 950 }}>
        <Header
          logoPath={"/images/backgroundLogoWhite.svg"}
          backgounrColor={"#0d41a6"}
          signedIn={true}
        />
        <div className="container-fluid ">
          {" "}
          <div className="row">
            <div className="sideMenucolumn" style={{ height: "91.4vh" }}>
              <SideMenus menuType={"settings"} menuSelected={"account"} />
            </div>
            <div
              className="col contentColumn"
              style={{
                height: "91.4vh",
                overflow: "scroll",
                overflowX: "hidden",
                overflowY: "hidden",
              }}
            >
              <SettingsHeader title={"Account"} />
              <br></br>
              <div className="container">
                <div className="row" style={{ marginLeft: "28px" }}>
                  <div className="col-2">First name</div>
                  <div className="col-5">
                    {" "}
                    <input
                      className="updateProfileInputs"
                      disabled={loadingStatus !== "none"}
                      value={firstName}
                      onChange={handleFirstNameChange}
                      type="text"
                    ></input>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="container">
                <div className="row" style={{ marginLeft: "28px" }}>
                  <div className="col-2">Last name</div>
                  <div className="col-5">
                    {" "}
                    <input
                      onChange={handleLastNameChange}
                      value={lastName}
                      disabled={loadingStatus !== "none"}
                      type="text"
                      className="updateProfileInputs"
                    ></input>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="container">
                <div className="row" style={{ marginLeft: "28px" }}>
                  <div className="col-2">Email Adress</div>
                  <div className="col-5">
                    {" "}
                    <input
                      type="email"
                      disabled
                      value={email}
                      className="updateProfileInputs"
                    ></input>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="container">
                <div className="row" style={{ marginLeft: "28px" }}>
                  <div className="col-2">Phone Number</div>
                  <div className="col-5">
                    {" "}
                    <input
                      type="text"
                      disabled
                      value={phone}
                      className="updateProfileInputs"
                    ></input>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="container">
                <div className="row" style={{ marginLeft: "28px" }}>
                  <div className="col-2"></div>
                  <div className="col-5">
                    {" "}
                    <button
                      className="updateProfileApplyButton"
                      onClick={applyProfileChanges}
                      disabled={loadingStatus !== "none"}
                    >
                      {loadingStatus !== "update profile" && (
                        <span>Update profile</span>
                      )}
                      {loadingStatus === "update profile" && <Spinner />}
                    </button>
                  </div>
                </div>
              </div>

              <br></br>
              <div className="container">
                <div className="row" style={{ marginLeft: "28px" }}>
                  <div className="col-2">Old Password</div>
                  <div className="col-5">
                    {" "}
                    <input
                      value={oldPassword}
                      onChange={handleOldPasswordChange}
                      disabled={loadingStatus !== "none"}
                      type="password"
                      className="updateProfileInputs"
                    ></input>
                  </div>
                </div>
              </div>

              <br></br>
              <div className="container">
                <div className="row" style={{ marginLeft: "28px" }}>
                  <div className="col-2">New Password</div>
                  <div className="col-5">
                    {" "}
                    <input
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                      disabled={loadingStatus !== "none"}
                      type="password"
                      className="updateProfileInputs"
                    ></input>
                  </div>
                </div>
              </div>

              <br></br>
              <div className="container">
                <div className="row" style={{ marginLeft: "28px" }}>
                  <div className="col-2">Confirm password</div>
                  <div className="col-5">
                    {" "}
                    <input
                      value={newPasswordConfirm}
                      onChange={handleNewPasswordConfirmChange}
                      disabled={loadingStatus !== "none"}
                      type="password"
                      className="updateProfileInputs"
                    ></input>
                  </div>
                </div>
              </div>

              <br></br>
              <div className="container">
                <div className="row" style={{ marginLeft: "28px" }}>
                  <div className="col-2"></div>
                  <div className="col-5">
                    {" "}
                    <button
                      className="updateProfileApplyButton"
                      onClick={applyPasswordChanges}
                      disabled={loadingStatus !== "none"}
                    >
                      {loadingStatus !== "edit pass" && (
                        <span>Update password</span>
                      )}
                      {loadingStatus === "edit pass" && <Spinner />}
                    </button>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="container">
                <div className="row" style={{ marginLeft: "28px" }}>
                  <div className="col-2"></div>
                  <div className="col-5">
                    {" "}
                    <button
                      className="updateProfileDeleteButton"
                      disabled={loadingStatus !== "none"}
                      onClick={deleteAccount}
                    >
                      {loadingStatus !== "delete account" && (
                        <span>Delete account</span>
                      )}
                      {loadingStatus === "delete account" && <Spinner />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateProfile;
