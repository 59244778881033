import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideMenus from "./SideMenus";
import { getTokenUsageApi } from "../domain/myApisConsumption";
import { useSelector } from "react-redux";
import SettingsHeader from "./SettingsHeader";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Helmet from "react-helmet";


function Usage() {
  const userInfo = useSelector((state) => state.userInfo) || {};
  const [loadingBool, setLoadingBool] = useState(true);
  const [creditUsageLastDay, setCreditUsageLastDay] = useState();
  const [creditUsageLastSevenDays, setCreditUsageLastSevenDays] = useState();
  const [creditUsageLastThirtyDays, setCreditUsageLastThirtyDays] = useState();
  const [tokenUsageLastDay, setTokentUsageLastDay] = useState();
  const [tokenUsageLastSevenDays, setTokentUsageLastSevenDays] = useState();
  const [tokenUsageLastThirtyDays, setTokentUsageLastThirtyDays] = useState();

  useEffect(() => {
    const fetchData = async () => {
      toast.dismiss();
      try {
        let myTokenResponse = await getTokenUsageApi(userInfo.id);
        console.log(myTokenResponse);
        setCreditUsageLastDay(myTokenResponse.data.credit_usage.last_day + " USD");
        setCreditUsageLastSevenDays(myTokenResponse.data.credit_usage.last_7_days + " USD");
        setCreditUsageLastThirtyDays(myTokenResponse.data.credit_usage.last_30_days + " USD");

        setTokentUsageLastDay(myTokenResponse.data.token_usage.last_day);
        setTokentUsageLastSevenDays(myTokenResponse.data.token_usage.last_7_days);
        setTokentUsageLastThirtyDays(myTokenResponse.data.token_usage.last_30_days);
        setLoadingBool(false);
      } catch (e) {
        setLoadingBool(false);
        console.log(e);
        toast.error("Couldn't fetch your credit/tokens usage", { autoClose: false });
      }
    };

    fetchData();
  }, []);
  return (
    <>
    <Helmet>
        <title>Epsibot usage</title>
      </Helmet>
      <Header
         logoPath={"/images/backgroundLogoWhite.svg"}
        backgounrColor={"#0d41a6"}
        signedIn={true}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="sideMenucolumn col-auto" style={{ height: "95vh" }}>
            <SideMenus menuType={"settings"} menuSelected={"usage"} />
          </div>
          <div
            className="col contentColumn"
            style={{
              height: "95vh",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <SettingsHeader title={"Usage"} />
            <div className="row mt-5">
              <div className="col-12">
                <label
                  style={{ marginLeft: 52 }}
                  className="settingSubtitlesLabels"
                >
                  Credit Usage
                </label>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                {" "}
                <label className="settingDataLabels">In the last day</label>
              </div>

              <div className="col-6">
                {" "}
                {!loadingBool && (
                  <label className="settingDataLabels">
                    {creditUsageLastDay}
                  </label>
                )}
                {loadingBool && <Spinner size="sm" />}
              </div>
              <div className="col-3"></div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                {" "}
                <label className="settingDataLabels">In the last 7 days</label>
              </div>

              <div className="col-6">
                {" "}
                {!loadingBool && (
                  <label className="settingDataLabels">
                    {creditUsageLastSevenDays}
                  </label>
                )}
                {loadingBool && <Spinner size="sm" />}
              </div>
              <div className="col-3"></div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                {" "}
                <label className="settingDataLabels">In the last 30 days</label>
              </div>

              <div className="col-6">
                {" "}
                {!loadingBool && (
                  <label className="settingDataLabels">
                    {creditUsageLastThirtyDays}
                  </label>
                )}
                {loadingBool && <Spinner size="sm" />}
              </div>
              <div className="col-3"></div>
            </div>
            {/* <div className="row mt-3">
              <div className="col-3">
                {" "}
                <label className="settingDataLabels">
                  Since account creation
                </label>
              </div>

              <div className="col-6">
                {" "}
                <label className="settingDataLabels">$15</label>
              </div>
              <div className="col-3"></div>
            </div> */}
            <div className="row mt-5">
              <div className="col-12">
                <label
                  style={{ marginLeft: 52 }}
                  className="settingSubtitlesLabels"
                >
                  Token Usage
                </label>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                {" "}
                <label className="settingDataLabels">In the last day</label>
              </div>

              <div className="col-6">
                {" "}
                {!loadingBool && (
                  <label className="settingDataLabels">
                    {tokenUsageLastDay}
                  </label>
                )}
                {loadingBool && <Spinner size="sm" />}
              </div>
              <div className="col-3"></div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                {" "}
                <label className="settingDataLabels">In the last 7 days</label>
              </div>

              <div className="col-6">
                {" "}
                {!loadingBool && (
                  <label className="settingDataLabels">
                    {tokenUsageLastSevenDays}
                  </label>
                )}
                {loadingBool && <Spinner size="sm" />}
              </div>
              <div className="col-3"></div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                {" "}
                <label className="settingDataLabels">In the last 30 days</label>
              </div>

              <div className="col-6">
                {" "}
                {!loadingBool && (
                  <label className="settingDataLabels">
                    {tokenUsageLastThirtyDays}
                  </label>
                )}
                {loadingBool && <Spinner size="sm" />}
              </div>
              <div className="col-3"></div>
            </div>
            {/* <div className="row mt-3">
              <div className="col-3">
                {" "}
                <label className="settingDataLabels">
                  Since account creation
                </label>
              </div>

              <div className="col-6">
                {" "}
                <label className="settingDataLabels">500000</label>
              </div>
              <div className="col-3"></div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Usage;
