import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideMenus from "./SideMenus";
import MessageSubmitted from "./MessageSubmitted";
import Icon from "@mdi/react";
import { mdiSend } from "@mdi/js";
import { mdiDotsHorizontal } from "@mdi/js";
import Helmet from "react-helmet";

import {
  newChatApi,
  addQuestionApi,
  editQuestionApi,
  getSpecificChatByIdApi,
} from "../domain/myApisConsumption";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function ChatBot() {
  const userInfo = useSelector((state) => state.userInfo) || {};
  const [userMessageToSend, setUserMessageToSend] = useState("");

  const [newChat, setNewChat] = useState(true);
  const [textAreaDivFocused, setTextAreaDivFocused] = useState("");

  const toolTipDiv = useRef(null);
  const newChatId = useRef(null);
  const [toolTipStyle, setToolTipStyle] = useState(null);

  const [showToolTip, setShowToolTip] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [loadingBool, setLoadingBool] = useState(false);
  const [newChatToSendToSideMenu, setNewChatToSendToSideMenu] = useState(null);
  const [showStopButton, setShowStopButton] = useState(false);
  const [test, setTest] = useState(null);

  const ref = useRef(null);

  var messagesEnd = useRef();
  const messagesDivRef = useRef(null);
  const messagesDivRef2 = useRef(null);
  const moreSendMessagesOptions = () => {
    if (userMessageToSend !== "" && !loadingBool) {
      if (!messagesDivRef.current) {
        setToolTipStyle({
          position: "absolute",
          top: messagesDivRef2.current.clientHeight - 85,
          right: 120,
        });
        setShowToolTip(true);
      } else {
        setToolTipStyle({
          position: "absolute",
          top: messagesDivRef.current.clientHeight - 85,
          right: 120,
        });
        setShowToolTip(true);
      }
    }
    return;
  };

  const scrollToBottom = () => {
    try {
      //messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    } catch (e) {
      console.log(e);
    }
  };

  const addNewMessage = async (option, checkPreviousAnswer) => {
    toast.dismiss();
    // if (newChat) {
    //   setNewChat(false);
    // }

    // Create a new array with the existing items and the new item
    if (
      (containsOnlyWhitespace(userMessageToSend) ||
        userMessageToSend === "" ||
        loadingBool) &&
      !checkPreviousAnswer
    )
      return;

    // sendTwoMessagesAndCleanup(undefined);
    toast(
      <div>
        <div>
          <Spinner size="sm" /> {"Waiting response from the server..."}
        </div>
      </div>,
      {
        autoClose: false,
      }
    );
    let newChatRes;
    let newArray;
    let newQuestionRes;
    if (!checkPreviousAnswer) {
      newArray = [
        ...allMessages,
        {
          index: allMessages.length,
          id: guidGenerator(),
          type: "oneShotDisplay",
          sendedBy: "user",
          message: userMessageToSend,
        },
      ];
    } else {
      newArray = [
        ...allMessages,
        {
          index: allMessages.length,
          id: guidGenerator(),
          type: "oneShotDisplay",
          sendedBy: "user",
          message:
            "Check the previous response for potential mistakes and give a response with no mistakes.",
        },
      ];
    }

    // setAllMessages(newArray);

    setUserMessageToSend("");
    setLoadingBool(true);

    if (newChat) {
      try {
        newChatRes = await newChatApi(userInfo.id, userMessageToSend, option);
        if (newChatRes.data && newChatRes.data.status === 1) {
          newArray.push({
            index: allMessages.length,
            id: guidGenerator(),
            type: "dynamic",
            sendedBy: "epsiBot",
            message: newChatRes.data.response,
          });
          newChatId.current = newChatRes.data.chat_id;
          setNewChat(false);

          setAllMessages(newArray);
          setShowStopButton(true);

          setNewChatToSendToSideMenu({
            chat_id: newChatRes.data.chat_id,
            title: newChatRes.data.title,
          });
        } else if (newChatRes.data && newChatRes.data.status === 0) {
          toast.dismiss();
          setLoadingBool(false);
          toast.error(newChatRes.data.response, { autoClose: false });
        }
      } catch (e) {
        toast.dismiss();
        setLoadingBool(false);
        toast.error("Couldn't create a new chat : " + e); //"Couldn't send your message to the server"
        console.log(e);
      }
    } else {
      try {
        if (!checkPreviousAnswer) {
          newQuestionRes = await addQuestionApi(
            userInfo.id,
            newChatId.current,
            userMessageToSend,
            option
          );
        } else {
          newQuestionRes = await addQuestionApi(
            userInfo.id,
            newChatId.current,
            "Check the previous response for potential mistakes and give a response with no mistakes.",
            option
          );
        }

        if (newQuestionRes.data && newQuestionRes.data.status === 1) {
          newArray.push({
            id: guidGenerator(),
            type: "dynamic",
            sendedBy: "epsiBot",
            message: newQuestionRes.data.response,
          });
          setAllMessages(newArray);
          setShowStopButton(true);
        } else {
          toast.dismiss();
          setLoadingBool(false);
          toast.error(newQuestionRes.data.response, { autoClose: false });
        }
      } catch (e) {
        toast.dismiss();
        setLoadingBool(false);
        toast.error("Couldn't add a new question: " + e); //"Couldn't send your message to the server"
        console.log(e);
      }
    }
  };

  // const sendTwoMessagesAndCleanup = (message) => {
  //   let newArray;
  //   if (!message) {
  //     newArray = [
  //       ...allMessages,
  //       {
  //         id: allMessages.length,
  //         type: "oneShotDisplay",
  //         sendedBy: "user",
  //         message: userMessageToSend,
  //       },
  //       {
  //         id: allMessages.length + 1,
  //         type: "dynamic",
  //         sendedBy: "epsiBot",
  //         message: `To find the limit of the sequence \\( a_n = \\frac{n}{n^2+1} \\) as \\( n \\) approaches infinity, we can identify the dominant terms in both the numerator and the denominator. The dominant term in the numerator is \\( n \\), and the dominant term in the denominator is \\( n^2 \\), since these terms will grow the fastest as \\( n \\) becomes very large.

  //         Thus, the behavior of the sequence for very large \\( n \\) will be similar to the behavior of the sequence formed by the dominant terms alone: \\( a_n \\approx \\frac{n}{n^2} \\).

  //         We can simplify \\( \\frac{n}{n^2} \\) by recognizing it as \\( \\frac{1}{n} \\), using the property \\( a^n/b^n = (a/b)^n \\):

  //         \\( \\frac{n}{n^2} = \\frac{n^1}{n^2} = \\frac{1}{n^{2-1}} = \\frac{1}{n} \\).

  //         The limit of \\( \\frac{1}{n} \\) as \\( n \\) approaches infinity is 0, since the denominator becomes larger and larger, making the value of the fraction smaller and smaller.

  //         Therefore, the limit of the sequence \\( a_n = \\frac{n}{n^2+1} \\) as \\( n \\) approaches infinity is 0:

  //         \\[ \\lim_{{n \\to \\infty}} \\frac{n}{n^2+1} = 0. \\]`,
  //       },
  //     ];
  //   } else {
  //     newArray = [
  //       ...allMessages,
  //       {
  //         id: allMessages.length,
  //         type: "oneShotDisplay",
  //         sendedBy: "user",
  //         message: message,
  //       },
  //       {
  //         id: allMessages.length + 1,
  //         type: "dynamic",
  //         sendedBy: "epsiBot",
  //         message:
  //           "my first equation  ever $2^{(n+1)} > (n+1) + 8$ this is a sample equation with the first being: $2^{(n+1)} > (n+1) + 8$ , and the second being $(E = mc^{2})$ and no further from now on",
  //       },
  //     ];
  //   }

  //   // Update the state with the new array
  //   setAllMessages(newArray);
  //   setUserMessageToSend("");
  //   scrollToBottom();
  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [allMessages]);

  const textAreaKeyPress = (event) => {
    var key = window.event.keyCode;

    if (key === 13 && userMessageToSend !== "" && !event.shiftKey) {
      event.preventDefault();
      addNewMessage("", false);
      return false;
    }
  };

  const containsOnlyWhitespace = (inputString) => {
    // Regular expression to check if the string contains only whitespace characters
    const regex = /^[\t \r\n]*$/;

    return regex.test(inputString);
  };

  const sendEditMessage = async (message, id, index) => {
    let newArray = [...allMessages];
    // let transitionArray = [];

    // for (let i = 0; i < newArray.length; i++) {
    //   if (newArray[i].id !== id) {
    //     transitionArray.push(newArray[i]);
    //   } else {
    //     transitionArray.push(newArray[i]);
    //     transitionArray[i].message = message;
    //     try {
    //       let updateQuestionRes = await editQuestionApi(
    //         userInfo.id,
    //         newChatId.current,
    //         message,
    //         index
    //       );
    //       newArray.push({
    //         id: guidGenerator(),
    //         type: "dynamic",
    //         sendedBy: "epsiBot",
    //         message: updateQuestionRes.data,
    //       });
    //       setAllMessages(newArray);
    //       setLoadingBool(false);
    //       break;
    //     } catch (e) {
    //       setLoadingBool(false);
    //       console.log(e);
    //       toast.error("Couldn't edit your question");
    //     }
    //   }
    // }
    toast(
      <div>
        <div>
          <Spinner size="sm" /> {"Waiting response from the server..."}
        </div>
      </div>,
      {
        autoClose: false,
      }
    );
    setLoadingBool(true);
    newArray = newArray
      .map((item, i) => {
        if (i + 1 === index) {
          return { ...item, message: message };
        }

        if (i + 1 < index) {
          return { ...item };
        } else {
          return undefined; // Signal to remove the item from the array
        }
      })
      .filter((item) => item !== undefined);
    setAllMessages(newArray);

    try {
      let updateQuestionRes = await editQuestionApi(
        userInfo.id,
        newChatId.current,
        message,
        index
      );
      if (updateQuestionRes.data && updateQuestionRes.data.status === 1) {
        newArray.push({
          id: guidGenerator(),
          type: "dynamic",
          sendedBy: "epsiBot",
          message: updateQuestionRes.data.response,
        });
        setAllMessages(newArray);
        setShowStopButton(true);
        setTest(false);
      } else if (
        updateQuestionRes.data &&
        updateQuestionRes.data.status === 0
      ) {
        toast.dismiss();
        setLoadingBool(false);
        toast.error(updateQuestionRes.data.response, { autoClose: false });
      }
    } catch (e) {
      toast.dismiss();
      setLoadingBool(false);
      console.log(e);
      toast.error("Couldn't edit your question");
    }

    // newArray.push({
    //   id: guidGenerator(),
    //   type: "dynamic",
    //   sendedBy: "epsiBot",
    //   message: `To find the limit of the sequence \\( a_n = \\frac{n}{n^2+1} \\) as \\( n \\) approaches infinity, we can identify the dominant terms in both the numerator and the denominator. The dominant term in the numerator is \\( n \\), and the dominant term in the denominator is \\( n^2 \\), since these terms will grow the fastest as \\( n \\) becomes very large.

    //   Thus, the behavior of the sequence for very large \\( n \\) will be similar to the behavior of the sequence formed by the dominant terms alone: \\( a_n \\approx \\frac{n}{n^2} \\).

    //   We can simplify \\( \\frac{n}{n^2} \\) by recognizing it as \\( \\frac{1}{n} \\), using the property \\( a^n/b^n = (a/b)^n \\):

    //   \\( \\frac{n}{n^2} = \\frac{n^1}{n^2} = \\frac{1}{n^{2-1}} = \\frac{1}{n} \\).

    //   The limit of \\( \\frac{1}{n} \\) as \\( n \\) approaches infinity is 0, since the denominator becomes larger and larger, making the value of the fraction smaller and smaller.

    //   Therefore, the limit of the sequence \\( a_n = \\frac{n}{n^2+1} \\) as \\( n \\) approaches infinity is 0:

    //   \\[ \\lim_{{n \\to \\infty}} \\frac{n}{n^2+1} = 0. \\]`,
    // });

    // setAllMessages(newArray);
  };

  const guidGenerator = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  };

  const newChatClicked = () => {
    setNewChat(true);
    setAllMessages([]);
  };
  const chatHistoryItemSelected = async (id) => {
    setNewChat(false);
    newChatId.current = id;
    setLoadingBool(true);

    try {
      let myChatResponse = await getSpecificChatByIdApi(newChatId.current);
      let newArray = [];

      //         id: allMessages.length,
      //         type: "oneShotDisplay",
      //         sendedBy: "user",
      //         message: userMessageToSend,
      //       },

      newArray = myChatResponse.data.messages
        .map((item, i) => {
          if (item.role === "user") {
            return {
              ...item,
              index: i - 1,
              id: guidGenerator(),
              type: "oneShotDisplay",
              sendedBy: "user",
              message: item.content,
            };
          }
          if (item.role === "assistant") {
            return {
              ...item,
              index: i - 1,
              id: guidGenerator(),
              type: "oneShotDisplay",
              sendedBy: "epsiBot",
              message: item.content,
            };
          } else return "toRemove";
        })
        .filter((item) => item !== "toRemove");

      setAllMessages(newArray);
      setLoadingBool(false);
      toast.dismiss();
    } catch (e) {
      console.log(e);
      toast.error("Couldn't load the chat room");
      setLoadingBool(false);
      toast.dismiss();
    }
  };

  const handleClickOutside = (event) => {
    if (toolTipDiv.current && !toolTipDiv.current.contains(event.target)) {
      // Clicked outside the rectangle, so hide the tooltip
      setShowToolTip(false);
    }
  };
  const checkMyPreviousAnswer = () => {
    addNewMessage("", true);
  };
  const stopGenerating = () => {
    let newArray = [...allMessages];
    newArray[newArray.length - 1].endDisplay = true;
    setAllMessages(newArray);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const messageEnded = () => {
    toast.dismiss();
    setLoadingBool(false);
  };

  const finalAnswerClick = () => {
    setShowToolTip(false);
    addNewMessage("short", false);
  };
  const setpsOnlyClick = () => {
    setShowToolTip(false);
    addNewMessage("steps", false);
  };
  const detailedAnswerClick = () => {
    setShowToolTip(false);
    addNewMessage("details", false);
  };
  const myChatItemClicked = () => {
    setNewChat(false);
  };

  return (
    <>
      <Helmet>
        <title>Epsibot chat</title>
      </Helmet>
      <div style={{ minWidth: 950 }}>
        <Header
          logoPath={"/images/backgroundLogoWhite.svg"}
          backgounrColor={"#0d41a6"}
          signedIn={true}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="sideMenucolumn chatBotSideMenu col-auto">
              <SideMenus
                menuType={"chat"}
                newChatClicked={newChatClicked}
                myChatItemClicked={myChatItemClicked}
                chatHistoryItemSelected={chatHistoryItemSelected}
                newChatItemToSend={newChatToSendToSideMenu}
              />
            </div>
            <div className="col">
              {newChat && (
                <div
                  ref={messagesDivRef2}
                  style={{
                    height: "75vh",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <div className="row" style={{ marginTop: 150 }}>
                    <div className="col-4"></div>
                    <div className="col-4 text-center">
                      <img
                        alt=""
                        src="/images/epsibotIcon.svg"
                        style={{ height: 80, width: 80 }}
                      ></img>
                    </div>
                    <div className="col-4"></div>
                  </div>
                  <div className="row" style={{ marginTop: 50 }}>
                    <div className="col-2"></div>
                    <div className="col-8 text-center">
                      <label className="mathQuestionsLabelInChat">
                        <b>Instructions on using EpsiBot</b>
                        <p></p>
                        <ul style={{ fontSize: "12pt", textAlign: "left" }}>
                          <li>
                            {" "}
                            <b>Maintain Topic Consistency:</b> Keep the
                            conversation focused on a single topic.
                          </li>
                          <li>
                            {" "}
                            <b>Simplify Questions:</b> Instead of asking complex questions, 
							break them down into simpler, more specific parts.
							{" "}
                          </li>
                          <li>
                            {" "}
                            <b>Follow-Up:</b> Don't hesitate to ask follow-up
                            questions for additional clarity.{" "}
                          </li>
                          <li>
                            {" "}
                            <b>Provide Feedback:</b> Your feedback helps improve
                            the chatbot's accuracy and usefulness.{" "}
                          </li>
                          <li>
                            {" "}
                            <b>Check Answers:</b> Always double-check the
                            chatbot's solutions independently.{" "}
                          </li>
                          <li>
                            {" "}
                            <b>Utilize Approximate LaTeX:</b> Feel free to use
                            an approximate LaTeX syntax for mathematical
                            expressions.{" "}
                          </li>
                          <li>
                            {" "}
                            <b>Language Flexibility:</b> You're welcome to
                            communicate in any language you're comfortable with.{" "}
                          </li>
                        </ul>
                      </label>
                    </div>
                    <div className="col-3"></div>
                  </div>
                </div>
              )}

              {!newChat && (
                <div
                  ref={messagesDivRef}
                  style={{
                    height: "75vh",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {allMessages.map((myElement, index) => (
                    <MessageSubmitted
                      sendEditMessage={sendEditMessage}
                      setGeneratingResponseBool={() => {
                        toast.dismiss();
                        setLoadingBool(false);
                        setShowStopButton(false);
                      }}
                      index={index + 1}
                      id={myElement.id}
                      key={myElement.id}
                      sendedBy={myElement.sendedBy}
                      message={myElement.message}
                      type={myElement.type}
                      isGeneratingMessage={loadingBool}
                      scrollToBottom={scrollToBottom}
                      messageEnded={messageEnded}
                      chat_id={newChatId.current}
                      checkMyPreviousAnswer={checkMyPreviousAnswer}
                      endDisplay={myElement.endDisplay}
                      length={allMessages.length}
                    />
                  ))}

                  <div
                    style={{ float: "left", clear: "both" }}
                    ref={messagesEnd}
                  ></div>
                </div>
              )}
              <div className="row mt-3">
                <div className="col-2"></div>
                <div
                  className={`col-8 textAreaDiv text-left d-flex ${textAreaDivFocused}`}
                  style={{ position: "relative" }}
                >
                  <textarea
                    className="myMessageTextArea"
                    placeholder="Write your message here"
                    value={userMessageToSend}
                    onChange={(event) => {
                      setUserMessageToSend(event.target.value);
                    }}
                    onKeyPress={(event) => {
                      textAreaKeyPress(event);
                    }}
                    onFocus={() => {
                      setTextAreaDivFocused("textAreaDivFocused");
                    }}
                    onBlur={() => {
                      setTextAreaDivFocused("");
                    }}
                  ></textarea>

                  {!showStopButton && (
                    <div
                      onClick={() => addNewMessage("", false)}
                      style={{
                        cursor:
                          loadingBool ||
                          containsOnlyWhitespace(userMessageToSend) ||
                          userMessageToSend === ""
                            ? "not-allowed"
                            : "pointer",
                      }}
                      className="mt-2 ms-5"
                    >
                      <img
                        style={{ height: 20, width: 24 }}
                        src="/images/send.svg"
                        alt=""
                      />
                    </div>
                  )}
                  {showStopButton && (
                    <div
                      onClick={() => stopGenerating()}
                      style={{
                        cursor: "pointer",
                      }}
                      className="mt-2 ms-5"
                    >
                      <img
                        style={{ height: 20, width: 24 }}
                        src="/images/stop.svg"
                        alt=""
                      />
                    </div>
                  )}

                  <div
                    onClick={moreSendMessagesOptions}
                    style={{
                      cursor:
                        containsOnlyWhitespace(userMessageToSend) ||
                        userMessageToSend === ""
                          ? "not-allowed"
                          : "pointer",
                    }}
                    className="mt-2 ms-1"
                  >
                    <Icon path={mdiDotsHorizontal} size={1} />
                  </div>
                </div>

                <div className="col-1">
                  {" "}
                  {showToolTip && (
                    <div
                      ref={toolTipDiv}
                      className="chatBotToolTip alignToolTip"
                      style={toolTipStyle}
                    >
                      <div class="container-fluid">
                        <div class="row">
                          <div
                            onClick={finalAnswerClick}
                            class="col-12 mt-2 rename-delete-popup centerLeft finalAnswerActionClass text-left"
                          >
                            <label style={{ marginTop: 5, cursor: "pointer" }}>
                              Short answer
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="container-fluid">
                        <div class="row ">
                          <div
                            onClick={setpsOnlyClick}
                            class="col-12 mt-2 rename-delete-popup centerLeft stepsOnlyActionClass"
                          >
                            <label style={{ marginTop: 5, cursor: "pointer" }}>
                              Steps only
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="container-fluid">
                        <div class="row ">
                          <div
                            onClick={detailedAnswerClick}
                            class="col-12 mt-2 rename-delete-popup centerLeft detailedAnswerActionClass"
                          >
                            <label style={{ marginTop: 5, cursor: "pointer" }}>
                              Detailed answer
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatBot;
